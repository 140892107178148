import NavBar from "../../../navbar/navbar.componet";

const Blog = () => {
    
        return (
    
            <div>
                <NavBar />
                <div className="blog-container">
                    <h1>Blog</h1>
                    <p>Coming Soon!</p>
                </div>   
            </div>
    
        );
        }

export default Blog;