import CheckboxTile from '../../checkbox/checkbox.component';
import { transmissionArray } from '../../../data/vehicleOptions';
import ValidationMessage from '../../validation/validation.component';

const VehicleTransmission = () => {
    
    return (
        <>
        <h2>* Select Preferred Transmission Types</h2>
        <ValidationMessage message={'Please select at least one transmission type'} />
        {transmissionArray.map((type) => {
            return(
            <CheckboxTile key={type} label={type} value={type} />
            )
        })}

        </>
    )
}

export default VehicleTransmission;