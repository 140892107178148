import { useState, useContext } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import SliderElectric from "../../input/input-electric.component";

const VehicleRange = () => {

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    const updateValue = (newValue) => {
        setVehicleObject({...vehicleObject, range: newValue})
    }

    return (
        <div className="slider">
            <h2>Select Minimum Electric Range</h2>
            <SliderElectric onValueChange={updateValue} value={vehicleObject.range} lowMsg="less range" highMsg="more range" />
        </div>
    );
  };
  
  export default VehicleRange;