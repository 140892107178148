import { useState, useContext, useEffect, useRef } from 'react';
import { Fade, Bounce, Zoom } from 'react-reveal';
import { Configuration, OpenAIApi } from 'openai';
import axios from 'axios';
import { VehicleContext } from "../../use context/vehicle-object.context";
import Loader from '../../loader/loader-component';
import './vehicle-selector2.style.scss';
import MoreInformation from './more-information/more-information.component';
import HoverItem from './hover-item/hover-item.component';
import { hover } from '@testing-library/user-event/dist/hover';
import NoResults from './no-results/no-results.component';


const VehicleSelector2 = ({setShowSideBarFalse, setVehicleSelector2PageTrue, setHideNextBtn, setShowSideBarTrue, updateReRunFunction, hideVehicleCardsFunction, hideVehicleCards }) => {

const [vehicleData, setVehicleData] = useState([]);
const [matchArray, setMatchArray] = useState([]);
const [showLoader, setShowLoader] = useState(true);
const [mobileScreen, setMobileScreen] = useState(true);
const [changeMySearch, setChangeMySearch] = useState(false);
const [hoveredItem, setHoveredItem] = useState(null);
const [vehicleInfoPage, setVehicleInfoPage] = useState(null);
const [vehicleWriteUp, setVehicleWriteUp] = useState([]);

let editButton

if (hideVehicleCards) {
  editButton = 'Back'
} else {
  editButton = 'Edit'
} 

const isInitialMount = useRef(true);

let listItems;

useEffect(() => {
  setVehicleSelector2PageTrue()
}, [])

useEffect(() => {
  reRunFunction()
}, [updateReRunFunction])

// REVIEW FUNCTION, ONLY CALL GETDATA IF YEAR OR PRICE IS CHANGEDheroku logs --tail
const reRunFunction = () => {
  setVehicleData([])
  setMatchArray([])
  getData()
  getVehicleScore()
  displayUniqueVehicles()
  setChangeMySearch(false)
}

const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

const handleResize = () => {
  if (window.innerWidth < 767) {
    setMobileScreen(true)
  } else {
    setMobileScreen(false)
  }
}

useEffect(() => {
  window.addEventListener("resize", handleResize)
})

// useEffect(() => {
//   if (isInitialMount.current) {
//      isInitialMount.current = false;
//   } else {
//     getVehicleScore()
//   }
// });

useEffect(() => {
  getVehicleScore()
}, [vehicleData])

  useEffect(() => {
    getData();
    handleResize();
  }, [])

    // useEffect(() => {
      
    // }, [vehicleWriteUp])
      
    useEffect(() => {
      setHideNextBtn()
    }, [setHideNextBtn])

const { 
    minYear,
    maxYear,
    minPrice, 
    maxPrice, 
    cargoRating,
    maintenanceRating,
    safetyRating, 
    fuelEfficiency, 
    luxuryRating, 
    brandAwareness, 
    depreciation, 
    range,
    category, 
    subCategory,
    drivetrain,
    fuel,
    transmission,
    seating,
    towing,
    maxTowWeight,
    conditions, 
    activities,
    children,
    sportRating,
    originCountry,
    categoryScore1,
    categoryScore2,
} = vehicleObject

const lowerMinPrice = minPrice - 3500
const lowerMaxPrice = maxPrice + 3500

// get score for their cargo requirements (each selection is worth 3.3333 points out of 10)
const cargoScore = cargoRating.length * 3.3333

const getData = () => {

  setShowLoader(true)

  axios.get('https://vehicle-database-selector.herokuapp.com/vehicles', {
      params: {
        minYear:minYear,
        maxYear:maxYear,
        minPrice:lowerMinPrice,
        maxPrice:lowerMaxPrice
      }
  })
  .then((res) => {
    console.log('axios/heroku call running')
    setVehicleData(res.data);
    setShowLoader(false);
    if(window.innerWidth >= 767) {
      setShowSideBarTrue();
    }
  })
  .catch(err => {
      console.log('axios/heroku call error')
      setShowLoader(false)
      console.error(err.response.data);    // ***
      console.error(err.response.status);  // ***
  })
  if(window.innerWidth >= 767) {
    setShowSideBarTrue();
    }
  
  };

const openai = new OpenAIApi(new Configuration({
  apiKey: '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'
  }));
  const generateVehicleWriteUp = (
    rating,
    luxury_score,
    safety_score,
    maintenance_score,
    depreciation_score,
    brand_score,
    activity_score,
    sport_score,
    vehicleYear,
    vehicleMake,
    vehicleModel,
    vehicleCargoScore, 
    vehicleMaintenanceScore, 
    vehicleDepreciationScore, 
    vehicleSafetyScore, 
    vehicleLuxuryScore, 
    vehicleBrandScore, 
    vehicleActivityScore, 
    vehicleConditionsScore,
    vehicleSportScore,
    ) => {
    setShowLoader(true)
  openai.createChatCompletion({
    model: 'gpt-3.5-turbo',
    messages: [{
      role: 'user',
       content: `
        A user has gone through several questions evaluating what options and values they prefer in a vehicle. I have then compared those against the objective scores of the vehicle, looking for a match.

        The ${rating} is out of 100, a score of 100 is the best match available for the user.

        The ${vehicleYear} ${vehicleMake} ${vehicleModel} has the following objective scores ranked out of 10. A score above a 7 indicates a good score. A score below a 5 indicates a poor score.
        luxury score: ${luxury_score}, the higher the score the more luxurious the car.
        safety score: ${safety_score}, the higher the score the safer the car.
        maintenance score: ${maintenance_score}, the lower the score the less maintenance the car needs.
        depreciation score: ${depreciation_score}, the lower the score the less the car depreciates.
        brand score: ${brand_score}, the higher the score the more prestigious the brand.
        activity score: ${activity_score}, the higher the score the more activities the car is good for.
        conditions score: ${activity_score}, the higher the score the more conditions the car is good for.
        cargo score: ${activity_score}, the higher the score the more cargo the car can hold.
        sport score: ${sport_score}, the higher the score the more sporty the car is.

        The scores below indicate how important the score above is to them. A score above .7 means the user and the vehicle are an good match, a score above .8 means they are a great match. A score below .5 means they are a poor match.
        A high score here does not mean that the vehicle objectively has a good score in that feature, it only means that the vehicle and the user are a good match based on what they are looking for.
        Here are a few examples to create context for your write up. If the 'luxury_score' is 8, and the user has ranked 'luxury' as 9, then the user and the vehicle are an excellent match. If the 'luxury_score' is 8, and the user has ranked 'luxury' as 4, then the user and the vehicle are a poor match. 

        luxury score: ${vehicleLuxuryScore}
        safety score: ${vehicleSafetyScore}        
        maintenance score: ${vehicleMaintenanceScore}
        depreciation score: ${vehicleDepreciationScore}        
        brand score: ${vehicleBrandScore}        
        activity score: ${vehicleActivityScore}
        conditions score: ${vehicleConditionsScore}
        cargo score: ${vehicleCargoScore}
        sport score: ${vehicleSportScore}

        Based on how well the user matches with the features, and the vehicles scores ranked out of 10, let the user know if this vehicle is a good fit for them in a short write up. Always assume it is a good match, and do not reference the actual values in the scores.
       `
      }],
  }).then((res) => {
    setShowLoader(false)
    setVehicleWriteUp(res.data.choices[0].message.content)
    console.log(res.data.usage.total_tokens)
  }).catch((err) => {
    console.error(err)
  });
}

const mobileChangeMySearch = () => {
  setChangeMySearch(true)
}

const handleItemHover = (vehicle) => {
  setHoveredItem(vehicle.id);
};

const handleItemLeave = () => {
  setHoveredItem(null);
};

const showVehicleInfoPage = (vehicle) => {
  setVehicleInfoPage(vehicle.id);
}

const hideVehicleInfoPage = () => {
  setVehicleInfoPage(null);
  setVehicleWriteUp([])
  if(window.innerWidth >= 767) {
  setShowSideBarTrue()
  }
}

    // get score for children and add to cargo then divide by 2 for a final score out of 10, evenly split between number of children and cargo they carry
    let theChildrenScore
    switch(true) {
          case children === '1':
            theChildrenScore = 3
          break;
          case children === "2":
            theChildrenScore = 5
          break;
          case children === "3":
            theChildrenScore = 7
          break;
          case children === "4+":
            theChildrenScore = 10
          break;
        default:
            theChildrenScore = 0
      }

    const combinedCargoScore = (cargoScore + theChildrenScore) / 2

    // get score for their activity requirements (each activity is worth 1 point, x2 to make it a score out of 10)
    const theActivityScore = activities.length * 2

    // get score for vehicle driving conditions (do not give any points for hwy or city driving)
    let theConditionsScore = 0;

    conditions.forEach(condition => {
        if (condition !== "Highway" && condition !== "City") {
            theConditionsScore += 2.5;
        }
        return theConditionsScore;
    });

    const fuelEfficiencyScores = {
        1: 10,
        2: 12,
        3: 14,
        4: 16,
        5: 18,
        6: 20,
        7: 22,
        8: 25,
        9: 30,
        10: 35,
      };

    const theFuelEfficiencyScore = fuelEfficiencyScores[Number(fuelEfficiency)] || 18;
    
    function checkFuelInRange(dbFuelMpg, userFuelMpg) {
      if (dbFuelMpg === '' || dbFuelMpg === 'electric' || dbFuelMpg === null || dbFuelMpg === undefined || dbFuelMpg === 0 || dbFuelMpg === '0'|| dbFuelMpg === 'NULL' || dbFuelMpg === 'null') {
        return 1;
      } else if (userFuelMpg <= dbFuelMpg) {
        return 1;
      } else {
        const fuelMpgDiff = userFuelMpg - dbFuelMpg;
        const score = fuelMpgDiff * .15;
        return 1 - score;
      }
    }

    const checkCargoInRage = (dbCargoRating, userCargoRating) => {
      const cargoDiff = Math.abs(dbCargoRating - userCargoRating)
      const score = (cargoDiff * .2)
      if(score < 0 || score > 1) {
        //  to avoid returning 0 / false and exluding vehicles with more or less cargo. Cargo should not eliminate a vehicle
        return .1;
      } else {
      return 1 - score;
      }
    }

    const checkConditionAndActivityRating = (dbVehicle, userVehicle) => {
      const diff = Number(dbVehicle) - Number(userVehicle);
      if (diff > 2) {
        return 0.1;
      } 
      if (diff <= 2 && diff >= 0) {
        return 1 - (diff * 0.25);
      } 
      if (diff < 0 && diff > -3.5) {
        return 1 - Math.abs(diff * 0.25);
      } 
      return 0.1;
    };

    const checkTheRating = (dbVehicle, userVehicle, multiplier) => {
      const difference = Math.abs(dbVehicle - userVehicle);
      const score = difference * multiplier;
      return 1 - score
    }

    const checkTowValue = (userTow, userTowWeight, dbTowWeight) => {
      if (userTow !== "Yes") {
        return 1;
      } 
        if(userTowWeight <= dbTowWeight) {
        return 1;
        } else {
          return 0;
        }
    };

    // if user leaves selection blank, return 1
    const checkForBooleanString = (dbVehicleString, userVehicleArray) => {
      if(userVehicleArray.length == 0 || userVehicleArray == "") {
        return 1;
      } else {
      for (let i = 0; i < userVehicleArray.length; i++) {
        if (userVehicleArray[i].toUpperCase() === dbVehicleString.toUpperCase()) {
          return 1;
          }
        }
      }
      return 0;
    }

    const checkForBooleanInt = (dbVehicleString, userVehicleArray) => {
      for (let i = 0; i < userVehicleArray.length; i++) {
        const dbVehicleNumber = Number(dbVehicleString);
        const userVehicleNumber = Number(userVehicleArray[i]);
        const isInRange = Math.abs(dbVehicleNumber - userVehicleNumber) <= 1;
        if (dbVehicleNumber === userVehicleNumber || isInRange) {
          return 1;
        }
      }
      return 0;
    }
    
    // const checkForSubCategory = (dbSubCategory, userSubCategory) => {
    //   if(userSubCategory == "") {
    //       return 1;
    //   } else {
    //       return dbSubCategory.some(item => userSubCategory.includes(item)) ? 1 : 0;
    //   }
    // }

    const checkForElectric = (dbVehicle, userVehicle, userRange) => {
      if (userVehicle !== "Electric") {
          return 1;
      }
      const diff = Math.abs(dbVehicle - userRange);
      if (dbVehicle >= userRange || diff <= 50) {
          return 1;
      }          
      return 1 - ((diff / 50) * 0.3);
      };

    const calculateCategoryScore = (categoryItem, categoryLabel, score, multiplier) => {
      if(categoryItem === categoryLabel) {
        score *= multiplier;
      }
    }
          
      function getVehicleScore () {

        vehicleData.forEach( vehicle => {
        const fuelScore = checkFuelInRange(vehicle.combined_mpg, theFuelEfficiencyScore)
        if(fuelScore <= 0 || fuelScore > 1 || fuelScore === NaN){
            return;
        }
        let cargoScore = checkCargoInRage(vehicle.activity_score, combinedCargoScore)
        if(cargoScore <= 0 || cargoScore > 1){
            return 0.1;
        }
        let maintenanceScore = checkTheRating(vehicle.maintenance_score, maintenanceRating, .25)
        if(maintenanceScore <= 0 || maintenanceScore > 1){
          return 0.1;
        }
        let depreciationScore = checkTheRating(vehicle.depreciation_score, depreciation, .25)
        if(depreciationScore <= 0 || depreciationScore > 1){
          return 0.1;
        }
        let safetyScore = checkTheRating(vehicle.safety_score, safetyRating, .33)
        if(safetyScore <= 0 || safetyScore > 1){
          return 0.1;
        }
        let luxuryScore = checkTheRating(vehicle.luxury_score, luxuryRating, .25)
        if(luxuryScore <= 0 || luxuryScore > 1){
          return 0.1;
        }
        let brandScore = checkTheRating(vehicle.brand_score, brandAwareness, .25)
        if(brandScore <= 0 || brandScore > 1){
          return 0.1;
        }
        let sportScore = checkTheRating(vehicle.sport_score, sportRating, .25)
        if(sportScore <= 0 || sportScore > 1){
          return 0.1;
        }
        const towScore = checkTowValue(towing, maxTowWeight, vehicle.max_tow_lbs)
        if(towScore <= 0 || towScore > 1){
            return;
        }
        const drivetrainMatch = checkForBooleanString(vehicle.drive_train, drivetrain)
        if(drivetrainMatch <= 0 || drivetrainMatch > 1){
            return;
        }
        const countryMatch = checkForBooleanString(vehicle.country_general, originCountry)
        if(countryMatch <= 0 || countryMatch > 1){
            return;
        }
        const categoryMatch = checkForBooleanString(vehicle.body_general, category)
        if(categoryMatch <= 0 || categoryMatch > 1){
            return;
        }
        // // const subCategoryMatch = checkForSubCategory(vehicle.sub_category, subCategory)
        // // if(subCategoryMatch <= 0 || subCategoryMatch > 1){
        // //     return;
        // // }
        const transmissionMatch = checkForBooleanString(vehicle.transmission_short, transmission)
        if(transmissionMatch <= 0 || transmissionMatch > 1){
            return;
        }
        const seatingMatch = checkForBooleanInt(vehicle.seats, seating)
        if(seatingMatch <= 0 || seatingMatch > 1){
            return;
        }
        const fuelTypeMatch = checkForBooleanString(vehicle.fuel_short, fuel)
        if(fuelTypeMatch <= 0 || fuelTypeMatch > 1){
            return;
        }
        let activityScore = checkConditionAndActivityRating(vehicle.activity_score, theActivityScore)
        if(activityScore <= 0 || activityScore > 1){
          return 0.1;
        }
        // use activity score to calculate conditions score (applicable to both)
        let conditionsScore = checkConditionAndActivityRating(vehicle.activity_score, theConditionsScore)
        if(conditionsScore <= 0 || conditionsScore > 1){
          return 0.1;
        }
        const electricScore = checkForElectric(vehicle.electric_range_km, fuel, range)
        if(electricScore <= 0 || electricScore > 1){
          return 0.1;
        }

        calculateCategoryScore(categoryScore1[0]['value'], 'Reliability', maintenanceScore, 2.5);
        calculateCategoryScore(categoryScore1[1]['value'], 'Reliability', maintenanceScore, 2);
        calculateCategoryScore(categoryScore1[2]['value'], 'Reliability', maintenanceScore, 1.5);
        calculateCategoryScore(categoryScore1[1]['value'], 'Budget', luxuryScore, 1.5);
        calculateCategoryScore(categoryScore1[2]['value'], 'Budget', luxuryScore, 2);
        calculateCategoryScore(categoryScore1[3]['value'], 'Budget', luxuryScore, 2.5);
        calculateCategoryScore(categoryScore1[0]['value'], 'Safety', safetyScore, 2.5);
        calculateCategoryScore(categoryScore1[1]['value'], 'Safety', safetyScore, 2);
        calculateCategoryScore(categoryScore1[2]['value'], 'Safety', safetyScore, 1.5);
        calculateCategoryScore(categoryScore1[0]['value'], 'Luxury', luxuryScore, 1.5);
        calculateCategoryScore(categoryScore1[1]['value'], 'Luxury', luxuryScore, 2);
        calculateCategoryScore(categoryScore1[2]['value'], 'Luxury', luxuryScore, 2.5);

        calculateCategoryScore(categoryScore2[0]['value'], 'Cargo', cargoScore, 2.5);
        calculateCategoryScore(categoryScore2[1]['value'], 'Cargo', cargoScore, 2);
        calculateCategoryScore(categoryScore2[2]['value'], 'Cargo', cargoScore, 1.5);
        calculateCategoryScore(categoryScore2[0]['value'], 'Activities', activityScore, 2.5);
        calculateCategoryScore(categoryScore2[1]['value'], 'Activities', activityScore, 2);
        calculateCategoryScore(categoryScore2[2]['value'], 'Activities', activityScore, 1.5);
        calculateCategoryScore(categoryScore2[0]['value'], 'Conditions', conditionsScore, 2.5);
        calculateCategoryScore(categoryScore2[1]['value'], 'Conditions', conditionsScore, 2);
        calculateCategoryScore(categoryScore2[2]['value'], 'Conditions', conditionsScore, 1.5);
        // NEED TO UPRANK VEHICLES WITH BETTER FUEL ECONOMY
        // calculateCategoryScore(categoryScore2[0]['value'], 'Fuel', fuelScore, 2.5);
        // calculateCategoryScore(categoryScore2[1]['value'], 'Fuel', fuelScore, 2);
        // calculateCategoryScore(categoryScore2[2]['value'], 'Fuel', fuelScore, 1.5);

        const totalScore = 
        cargoScore +
        maintenanceScore + 
        depreciationScore +
        luxuryScore +
        brandScore +
        activityScore +
        conditionsScore +
        sportScore +
        electricScore;
        // fuelScore +
        // towScore +
        // drivetrainMatch +
        // categoryMatch +
        // countryMatch +
        // // subCategoryMatch + 
        // transmissionMatch + 
        // seatingMatch +
        // fuelTypeMatch + 

        let tScore = totalScore.toFixed(3);

        setMatchArray(oldArray => [...oldArray, vehicle]);

        const vehicleScoreOutOfTen = (tScore/11)*10;

        vehicle.score = vehicleScoreOutOfTen.toFixed(1);
        // vehicle.vehicleFuelScore = fuelScore;
        vehicle.vehicleCargoScore = cargoScore;
        vehicle.vehicleMaintenanceScore = maintenanceScore;
        vehicle.vehicleDepreciationScore = depreciationScore;
        vehicle.vehicleSafetyScore = safetyScore;
        vehicle.vehicleLuxuryScore = luxuryScore;
        vehicle.vehicleBrandScore = brandScore;
        // vehicle.vehicleTowScore = towScore;
        vehicle.vehicleDrivetrainMatch = drivetrainMatch;
        vehicle.vehicleCategoryMatch = categoryMatch;
        vehicle.vehicleCountryMatch = countryMatch;
        vehicle.vehicleTransmissionMatch = transmissionMatch;
        vehicle.vehicleSeatingMatch = seatingMatch;
        vehicle.vehicleFuelTypeMatch = fuelTypeMatch;
        vehicle.vehicleActivityScore = activityScore;
        vehicle.vehicleConditionsScore = conditionsScore;
        vehicle.vehicleSportScore = sportScore;
        vehicle.vehicleElectricScore = electricScore;

        matchArray.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));

        } 
      )
    }

  // find all duplicate year and model vehicles, add the count of duplicates to new object array

  const displayUniqueVehicles = () => {
  
  const uniqueVehicles = [];
  const duplicates = {};
  
  for (let i = 0; i < matchArray.length; i++) {
    const vehicle = matchArray[i];
    const key = vehicle.year + vehicle.model;
  
    if (duplicates[key]) {
      duplicates[key]++;
    } else {
      duplicates[key] = 1;
      uniqueVehicles.push(vehicle);
    }
  }
  
  uniqueVehicles.forEach(vehicle => {
    const key = vehicle.year + vehicle.model;
    vehicle.duplicates = duplicates[key];
  });
  
  const firstVehicle = uniqueVehicles[0];

  matchArray.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));

  // console.log('unique vehicles',uniqueVehicles)

  if (uniqueVehicles.length > 0) {

    listItems = uniqueVehicles.map((vehicle) => {

        const testScore = vehicle.score

        const renderScore = (scoreName, scoreValue) => {
          const className = scoreValue > 0.7 ? 'high-score' : scoreValue < 0.5 ? 'low-score' : '';
          const formattedScore = Math.ceil(scoreValue * 100);
          return (
            <li className={className}>
              {scoreName} Match: {formattedScore}%
            </li>
          );
        };

        return (
          <>
          {!vehicleInfoPage &&
          <div key={vehicle.id} className='vehicle-card' onMouseEnter={() => handleItemHover(vehicle)}
            onMouseLeave={handleItemLeave}>
            {showLoader && <Loader /> }
            <img className='vehicle-img' 
              src={vehicle.image_url} 
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=require('../../images/image-placeholder.jpeg');
              }} />
            <div className='vehicle-ymm'>{vehicle.year} {vehicle.make} {vehicle.model}</div>
            <div className='vehicle-score'>
              <h5>Rating</h5>
              <h1>{Math.trunc(testScore/firstVehicle.score*100)}%</h1>
            </div>
            {hoveredItem === vehicle.id && (
            <>
              <HoverItem vehicle={vehicle} hoveredItem={hoveredItem} setShowSideBarFalse={setShowSideBarFalse} showVehicleInfoPage={showVehicleInfoPage} generateVehicleWriteUp={generateVehicleWriteUp} testScore={testScore} firstVehicle={firstVehicle} renderScore={renderScore} /> 
            </>
            )}
          </div>}
          {vehicleInfoPage && <MoreInformation vehicle={vehicle} mobileScreen={mobileScreen} vehicleInfoPage={vehicleInfoPage} hideVehicleInfoPage={hideVehicleInfoPage} vehicleWriteUp={vehicleWriteUp} showLoader={showLoader} />}
          </>
        );
    }
  );
  } else {
    listItems = !showLoader &&  (
      !changeMySearch && <NoResults mobileScreen={mobileScreen} setShowSideBarTrue={setShowSideBarTrue} mobileChangeMySearch={mobileChangeMySearch} /> 
    )
  }
}  

displayUniqueVehicles();

return (
  <>
  {mobileScreen ? 
    <div className={vehicleInfoPage ? 'vehicle-card-container-info' : 'vehicle-card-container'}>
      {showLoader ? null : vehicleInfoPage ? null : <button style={changeMySearch?{marginTop:'120px'} : {marginTop:'36px'}} className='edit-search-results-mobile' onClick={hideVehicleCardsFunction}>{editButton}</button>}
      {showLoader ? <Loader /> : null} 
      {!showLoader && !hideVehicleCards ? listItems : null}
      {/* <button className='update-function-btn' onClick={reRunFunction}>Update</button> */}
    </div> : 
    <div className={vehicleInfoPage === null ? 'vehicle-card-container' : 'vehicle-card-container-more-info'}>
      {/* <button className='edit-search-results-mobile' onClick={hideVehicleCardsFunction}>Edit</button> */}
      {showLoader ? <Loader /> : null} 
      {!showLoader && !hideVehicleCards ? listItems : null}
      {/* <button className='update-function-btn' onClick={reRunFunction}>Update</button> */}
    </div>
  }
  </>
    )
}

export default VehicleSelector2;



