import { useState, useContext, useEffect } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import Slider from "../../input/input";

const VehicleSport = () => {

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    const updateValue = (newValue) => {
        setVehicleObject({...vehicleObject, sportRating: newValue})
    }

    return(
        <div className='slider'>
            <h2>How Sporty do you want the vehicle?</h2>
            <Slider className={'slider-parent'} onValueChange={updateValue} value={vehicleObject.sportRating} lowMsg="less sporty" highMsg="more sporty" />
        </div>
    )
}

export default VehicleSport;