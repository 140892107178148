import './instructions.style.scss';
import { Fade, Zoom } from 'react-reveal';

const InstructionPage = () => { 

    return (
        <div className='instructions-container'>
            <div className="instructions-component">
                <h1>How does it work?</h1>
                    <div>
                        <ul>
                        <Fade>
                            <li>
                                <b>*</b> indicates a required field
                            </li>
                        </Fade>
                        <Fade delay={1250}>
                            <li>
                                Widen your search range to include more results
                            </li>
                        </Fade>
                        <Fade delay={3500}>
                            <li>
                                You can select multiple options in most scenarios
                            </li>
                        </Fade>
                        <Fade delay={4750}>
                            <li>
                                You can return to the previous screen to change your preferences    
                            </li>
                        </Fade>
                        <Fade delay={6000}>
                            <li>
                                The more defined your search is, the more specific your results will be
                            </li>
                        </Fade>
                    </ul>
                </div>
            </div>
        </div>

    )

}

export default InstructionPage;