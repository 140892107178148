import { useState } from 'react';
import { Link } from 'react-router-dom';
import Hamburger from 'hamburger-react';

import './navbar.style.scss'

const NavBar = ({ mobileScreen }) => {

    const [colorChange, setColorchange] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [showDropDownMenu, setShowDropDownMenu] = useState(false)

    const handleMouseOverNavBar = () => {
        setShowDropDownMenu(true)
    }

    const handleMouseLeaveNavBar = () => {
        setShowDropDownMenu(false)
    }

    const changeNavbarColor = () => {
        if (window.scrollY >= 60) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    
    window.addEventListener('scroll', changeNavbarColor);

    const HamburgerNavBar = () => {
        return (
            <div className='hamburger-navbar-container'>
                <Hamburger color="white" toggled={isOpen} toggle={setOpen} /> 
                {isOpen && 
                <div className='mobile-nav-container'>
                    <div className='mobile-nav-div font-24'>
                        <Link className='link-wheat' to='/'><p>Home</p></Link>
                        <Link className='link-wheat' to='/card'><p>Vehicle Selector</p></Link>
                        <Link className='link-wheat' to='/shopMap'><p>Find a Shop</p></Link>
                        <Link className='link-wheat' to='/vehicleDatabaseTool'><p>Vehicle Database</p></Link>
                        <Link className='link-wheat' to="/aboutUs"><p>About Us</p></Link>
                        <Link className='link-wheat' to="/contactUs"><p>Contact Us</p></Link>
                    </div>
                </div>}
            </div>
        )
    }

    const TopNavBar = () => {
        return (
        <div className={colorChange ? 'navbar-container-change' : 'navbar-container'}>
            <span className={colorChange ? 'logo-text-change' : 'logo-text'}><Link className={colorChange ? 'logo-text-change' : 'logo-text'} to='/'><i>GetMyCar</i></Link></span>
            <div className='navbar-div'>
                <Link className='link-wheat' to='/'><p>Home</p></Link>
                <p className='link-wheat' onMouseOver={handleMouseOverNavBar}>Tools</p>
                {showDropDownMenu && <div className='dropdown-navbar-menu' onMouseLeave={handleMouseLeaveNavBar}>
                    <Link className='link-wheat' to='/card'><p>Vehicle Selector</p></Link>
                    <Link className='link-wheat' to='/shopMap'><p>Find a Shop</p></Link>
                    <Link className='link-wheat' to='/vehicleDatabaseTool'><p>Vehicle Database</p></Link>
                </div>
                }
                <Link className='link-wheat' to="/aboutUs"><p>About Us</p></Link>
                <Link className='link-wheat' to="/contactUs"><p>Contact Us</p></Link>
            </div>
        </div>
        )
    }

    return (
        <>
            {mobileScreen ? <HamburgerNavBar /> : <TopNavBar />}
        </>
        
    )

}

export default NavBar;