import React, { useState } from "react";

import './input.componet.style.scss'

export default function SliderSimple(props) {

const handleChange = (event) => {
    props.onValueChange(event.target.value)
}
    
  return (
    <div className={props.className}>
      <div className="simple-slider-label"> 
      {props.value}
      </div>
      <input 
        className="input-simple-slider"
        type="range" 
        min={props.min} 
        max={props.max}
        step={props.step || 1}
        value={props.value}
        onChange={handleChange}
      />
    </div>
  );
}