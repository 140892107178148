import React, { useState, useContext, useEffect } from "react";
import { VehicleContext } from "../use context/vehicle-object.context";
import { bodyArray, drivetrainArray, transmissionArray, seatingArray, towingArray, fuelTypeArray, childrenArray, cargoArray, conditionsArray, activitiesArray, originArray } from "../../data/vehicleOptions";

import './checkbox.style.scss'

function CheckboxTile({ label, value }) {

  const {vehicleObject, setVehicleObject} = useContext(VehicleContext)
  const [lightMode, setLightMode] = useState(false)

  const [isChecked, setIsChecked] = useState(() => {
    if(      
      drivetrainArray.includes(label)
    ) {
    return vehicleObject.drivetrain.includes(value)
    }
    else if(
      bodyArray.includes(label)
    ) {
    return vehicleObject.category.includes(value)
    }
    else if(
      label === "Sedan" ||
      label === "Hatch" ||
      label === "Wagon" ||
      label === "Convertible" ||
      label === "Pickup"
    ) {
    return vehicleObject.subCategory.includes(value)
    }
    else if(
      fuelTypeArray.includes(label)
    ) {
    return vehicleObject.fuel.includes(value)
    }
    else if(
      transmissionArray.includes(label)
    ) {
    return vehicleObject.transmission.includes(value)
    }
    else if(
      seatingArray.includes(label)
    ) {
    return vehicleObject.seating.includes(value)
    }
    else if(
      childrenArray.includes(label)
    ) {
    return vehicleObject.children.includes(value)
    }
    else if(
      cargoArray.includes(label)
    ) {
    return vehicleObject.cargoRating.includes(value)
    }
    else if(
      conditionsArray.includes(label)
    ) {
    return vehicleObject.conditions.includes(value)
    }
    else if(
      activitiesArray.includes(label)
    ) {
    return vehicleObject.activities.includes(value)
    }
    else if(
      originArray.includes(label)
    ) {
    return vehicleObject.originCountry.includes(value)
    }
    else if(
      towingArray.includes(label)
    ) {
    return vehicleObject.towing.includes(value)
    } else {
      return ""
    }
});

const handleCheckboxChange = (event) => {
  const { checked } = event.target;
  if(
    drivetrainArray.includes(label)
    ) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      drivetrain: [...vehicleObject.drivetrain, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      drivetrain: vehicleObject.drivetrain.filter(
        (drivetrain) => drivetrain !== value
      ),
    });
  }
} else if (
  bodyArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      category: [...vehicleObject.category, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      category: vehicleObject.category.filter(
        (category) => category !== value
      ),
    });
  }
} else if (
  label === "Sedan" ||
  label === "Hatch" ||
  label === "Wagon" ||
  label === "Convertible" ||
  label === "Pickup"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      subCategory: [...vehicleObject.subCategory, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      subCategory: vehicleObject.subCategory.filter(
        (subCategory) => subCategory !== value
      ),
    });
  }
} else if (
  fuelTypeArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      fuel: [...vehicleObject.fuel, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      fuel: vehicleObject.fuel.filter(
        (fuel) => fuel !== value
      ),
    });
  }
} else if (
  transmissionArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      transmission: [...vehicleObject.transmission, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      transmission: vehicleObject.transmission.filter(
        (transmission) => transmission !== value
      ),
    });
  }
}  else if (
  seatingArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      seating: [...vehicleObject.seating, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      seating: vehicleObject.seating.filter(
        (seating) => seating !== value
      ),
    });
  }
}  else if (
  childrenArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      children: [...vehicleObject.children, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      children: vehicleObject.children.filter(
        (children) => children !== value
      ),
    });
  }
}  else if (
  cargoArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      cargoRating: [...vehicleObject.cargoRating, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      cargoRating: vehicleObject.cargoRating.filter(
        (cargoRating) => cargoRating !== value
      ),
    });
  }
}  else if (
  conditionsArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      conditions: [...vehicleObject.conditions, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      conditions: vehicleObject.conditions.filter(
        (conditions) => conditions !== value
      ),
    });
  }
}  else if (
  activitiesArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      activities: [...vehicleObject.activities, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      activities: vehicleObject.activities.filter(
        (activities) => activities !== value
      ),
    });
  }  
} else if (
  originArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      originCountry: [...vehicleObject.originCountry, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      originCountry: vehicleObject.originCountry.filter(
        (originCountry) => originCountry !== value
      ),
    });
  }  
} else if (
  towingArray.includes(label)
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      towing: [...vehicleObject.towing, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      towing: vehicleObject.towing.filter(
        (towing) => towing !== value
      ),
    });
  }
}
  setIsChecked(checked);
};

  return (
    <>
        <label
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width:'200px',
            height:'70px',
            // maxWidth: "160px",
            maxHeight: "120px",
            minWidth:'100px',
            // minHeight:'100px',
            borderRadius:'3px',
            margin: '15px auto',
            backgroundColor: lightMode ? isChecked ? 'rgb(235,235,235)' : 'rgba(255,255,255)' : isChecked ? 'rgba(255,255,255,0.2)' : 'rgba(44,72,81,0.3)',
            border: lightMode ? isChecked ? '1px solid rgba(136, 8, 8,.5)' : '1px solid rgb(5,5,5)' : isChecked ? '1px solid rgb(252,91,75)' : '1px solid white',
            cursor: 'pointer',
        }}
        >
        <h4>{label}</h4>
        <input
            type="checkbox"
            // style={{ display: 'none' }}
            // value={value}
            checked={isChecked}
            className="checkbox hidden"
            onChange={handleCheckboxChange} />            
        </label>
    </>
  );
}

export default CheckboxTile;