import NavBar from "../../../navbar/navbar.componet";
import { useState, useContext } from "react";
import { VehicleContext } from "../../../use context/vehicle-object.context";
import HeaderText from "../../../header text/header-text.component";
import companyName from "../../../../data/companyName";
import Shapes from "../../../shapes/shapes.component";
import Footer from "../../../footer/footer.component";
import '../../../../index.scss'
import '../../index/index.style.scss'

const AboutUs = () => {

  const { mobileView } = useContext(VehicleContext)

    const [email, setEmail] = useState('')
    const [subscribeMessage, setSubscribeMessage] = useState(false)

    return (

        <div>
            <NavBar mobileScreen={mobileView} />     
            <div className="about-us-container">
                <div className="about-us-info">
                <HeaderText text="About Us" />
                    <h1>Let Us Make It Easier For You.</h1>
                    <p className="spaced-out-text">
                    Our mission is to make the car buying experience as easy and stress-free as possible.
                    We are a team of car enthusiasts who understand the car buying process can be a stressful experience.
                    We have decades of industry experience working on both the purchasing and selling side of the transaction.
                    At {companyName} we leverage our unique team developers and car experts along with our propprietary technology
                    to help you make the best decision when purchasing or repairing your car.
                    </p>
                </div>
                <Shapes className="shapes-2" />
            </div>  
            <Footer email={email} setEmail={setEmail} subscribeMessage={subscribeMessage} setSubscribeMessage={setSubscribeMessage} />
        </div>

    );
    }

export default AboutUs;