import CheckboxTile from '../../checkbox/checkbox.component';
import { drivetrainArray } from '../../../data/vehicleOptions';
import ValidationMessage from '../../validation/validation.component';

const VehicleDriveTrain = () => {

    return (
        <>
            <h2>* Select Preferred Drivetrain</h2>
            <ValidationMessage message={'Please select at least one drivetrain'} />
            {drivetrainArray.map((type) => {
                return(
                <CheckboxTile key={type} label={type} value={type} />
                )
            })}
        </>
    )
}

export default VehicleDriveTrain;