import SelectorMainPage from './components/selector-main-page/selector.component';

import './index.scss';

function App() {
  return (
    <div className='app-container'>
      <SelectorMainPage />
    </div>
  );
}

export default App;
