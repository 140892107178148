import { useState, useContext } from "react";
import { VehicleContext } from "../../../use context/vehicle-object.context";
import NavBar from "../../../navbar/navbar.componet";
import HeaderText from "../../../header text/header-text.component";
import Footer from "../../../footer/footer.component";

const PurchasingTips = () => {

    const { mobileView } = useContext(VehicleContext)
    
        return (

            <div>
            <NavBar mobileScreen={mobileView} />
            <div className="purchasing-tips-header">
                <HeaderText text="Purchasing Tips" />
            </div>
            <div className="purchasing-tips-container">
                <div>
                    <h3>Stay within your Budget</h3>
                    <p>
                    Don't over purchase. Buyer's often get caught up in the excitement of making a new purchase, especially when it comes to vehicles.
                    Make sure to work within your budget and consider all likely expenses such as fuel (and fuel grade), insurance, maintenance, and repairs.
                    </p>
                </div>
                <div>
                    <h3>Consider depreciation</h3>
                    <p>
                    Buyers rarely consider depreciation when making a purchase. Depreciation is the single largest expense of vehicle ownership.
                    Consider this purchase an investment - usually one that loses money, but why not limit how much money you lose? It's even possible
                    to purchase a vehicle and sell it for more than you paid for it. Consider the scenario: You have a budget of $40,000, you could purchase
                    a 2020 Mercedes SUV or a 2022 Toyota SUV. The Mercedes will depreciate by approximately 20% in the first year, while the Toyota will
                    depreciate approximately 5%. After 3 years of ownership your Mercedes might be worth $24,000 while your Toyota is worth $34,000. Up front,
                    both vehicles cost the same, however when its time to cash in on your investment you spent $16,000 on the Mercedes and only $6,000 on the Toyota.
                    </p>
                </div>
                <div>
                    <h3>Think about the resale</h3>
                    <p>
                    Higher end options add up significantly when it comes to both purchasing a new vehicle and reselling a used one. Certain vehicles are easier
                    to sell with lower end options while other vehicles are considerably harder to sell without higher end options. Try to compare the value of
                    the vehicle you'd like to purchase to a similar vehicle that is a few years old. Look at the pattern in depreciation based on asking prices
                    and get an idea for how well this vehicle will sell with the options you want.
                    </p>
                </div>
                <div>
                    <h3>Budget for unexpected maintenance and repairs</h3>
                    <p>
                    This is inevitable. Vehicles are mechanical machines prone to wear and tear. Even the most reliable vehicles will require maintenance and repairs.
                    Make sure to be realistic and assume the norm. Don't talk yourself into a vehicle thinking you might "get lucky". Expensive vehicles with high end
                    equipment will always cost more to repair. Rare vehicles with limited manufacturing will have more expensive parts. Common vehicles with high production
                    will result in lower parts costs and generally lower labour costs. Be realistic and create an annual budget for expect and unexpected repairs.
                    </p>
                </div>
                <div>
                    <h3>Skip the dealership add ons</h3>
                    <p>
                    If buying from a dealership, skip the add ones. A dealership's primary source of profit comes from additional insurace, paint protections, warranties etc.
                    These add ons are often expensive and rarely used. Do not be fooled by weekly or monthly costs. Always consider the total cost. If you are unsure then asking
                    for more time to consider, the more pressure they apply for you to "make a decision now" the more often the add on is not worth it.
                    </p>
                </div>
                <div>
                    <h3>Do your research ahead of time</h3>
                    <p>
                    Show up prepared. Whether purchasing privately or from a dealership, make sure to do your homework ahead of time. Know the potential flaws on inherent issues
                    with the vehicle, research the average price, look at the vehicle history report. Making an impulse buy or only consulting in one person is a wreckless and often
                    regrettable way to purchase a vehicle.
                    </p>
                </div>
                <div>
                    <h3>Look at the vehicle history report</h3>
                    <p>
                    Ask for the vehicle VIN so you can review its history report. If the seller provides you with one make sure to check the date is was pulled. Look for accidents, 
                    declarations, liens and maintenance records. Accidents happen.. don't be frightened by smaller amounts (ex. $2500) and remember the more expensive the vehicles,
                    the more expensive the parts and labour. Watch out for declarations like "flood damage" or "Rebuilt title". These can lead to hidden issues and significantly
                    impact the resale value. Liens are very dangerous. As a buyer you may assume the debt of the seller if you purchase a vehicle with a lien. Lastly, look for maintenance
                    records. These will not always show on the history report however it may offer a potential bargaining point. 
                    </p>
                </div>
                <div>
                    <h3>Consider all upcoming expenses</h3>
                    <p>
                    Do the tires need to be replaced? Are the brakes worn? Is the vehicle approaching a major service like timing belt and water pump? These are often "small" or "normal"
                    items which are overlooked. They can add up quickly and significantly impact your budget. If the condition is unknown expect to spend money on it and budget accordingly.
                    </p>
                </div>
                <div>
                    <h3>Avoid long term financing</h3>
                    <p>
                    Long term financing can sound appealing. It can lower your monthly payments and allow you to purchase a more expensive vehicle. However it can also lead to negative equity
                    and signifcantly increase your overall cost of ownership. Make sure to review the finance terms and work out the exact amount of interest you will pay. Always consider other lendors
                    or using a line of credit rather than a vehicle loan. 
                    </p>
                </div>
                <div>
                    <h3>Consider trading in your current vehicle</h3>
                    <p>
                    When trading in your vehicle you get to deduct the value of your trade in from the purchase price of the new vehicle when paying taxes. For example, 
                    you are purchasing a $50,000 vehicle. Normally you would pay tax on the $50,000. However if you trade in your current vehicle for $10,000 you will only pay tax on $40,000.
                    However, do not just trade in your vehicle assuming this will save you money! Dealerships will often offer you less than the vehicle is worth on the private market.
                    Make sure to do your research and work out the actual savings vs selling it yourself.
                    </p>
                </div>
                <div>
                    <h3>Always get a third party inspection</h3>
                    <p>
                    This is critcal when buying privately. There is little protection when purchasing a vehicle privately. Always consider the old adage 'Buyer beware'. Ask the seller
                    to have the vehicle dropped at a third party shop and have a pre purchase inspection completed. These often cost around $150 and may save you thousands.
                    If the seller is hestitant to do this, consider it a red flag. 
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    
    
                    // stay within your Budget - dont overpurchase
                    // think about depreciation - sometimes its smarter to spend more up front
                    // think about how the add on options will impact future resale value
                    // prepare for unexpected maintenance
                    // think about fuel economy and fuel grade
                    // consider the cost of insurance
                    // delay the purchase of any add ons until you can conduct your own research
                    // avoid purchasing based off your monthly budget - always consider the overall cost
                    // do your research ahead of time
                    // if purchasing privately look to see if the vehicle is currently insured
                    // look at the ownership history - has it changed hands often/Frequently
                    // if purchasing from a privately look at the recent expenses - did they just buy new tires? why are they selling
                    // skip the add ons at the dealerships
                    // avoid long term financing - really understand your true cost to borrow
                    // don't think of costs on a monthly basis - consider the entire cost (ex. "its only 7.99/week")
                    // read the fineprint on any "freebies" like oil changes and tire replacement.. these perks are often very hard to cash in on when it comes time
                    // consider trading in to save the tax - BUT do the math, as you will generally receive less for your trade in than you would selling privately
                    // get a third part inspection or bring an experienced friend
    
        );
        }

export default PurchasingTips;
