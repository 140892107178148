import CheckboxTile from '../../checkbox/checkbox.component';
import { fuelTypeArray } from '../../../data/vehicleOptions';
import ValidationMessage from '../../validation/validation.component';

const VehicleFuelType = () => {

    return (
        <>
            <h2>* Select Preferred Fuel Types</h2>
            <ValidationMessage message={'Please select at least one fuel type'} />
            {fuelTypeArray.map((type) => {
                return(
                <CheckboxTile key={type} label={type} value={type} />
                )
            })}
        </>
    )
}

export default VehicleFuelType;