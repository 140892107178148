import NavBar from "../../../navbar/navbar.componet";

const Accessibility = () => {

    return (

        <div>
            <NavBar />
            <div className="accessibility-container">
            </div>   
        </div>

    );
    }

export default Accessibility;