import * as React from 'react'
import {  useState } from 'react'
import { Fade, Bounce, Zoom } from 'react-reveal';
import './vehicleDatabase.style.scss'

const DatabaseSearch = ({updateSearch, updateVehicleOptions}) => {

  const [vehicleInfo, setVehicleInfo] = useState({
    make: '',
    model: '',
  });
  const [selectedMinYear, setSelectedMinYear] = useState(null);
  const [toolTip, setToolTip] = useState(true)

  const makeArray = [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Bugatti',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Daewoo',
    'Dodge',
    'Ferrari',
    'FIAT',
    'Fisker',
    'Ford',
    'Genesis',
    'GMC',
    'Honda',
    'HUMMER',
    'Hyundai',
    'INFINITI',
    'Isuzu',
    'Jaguar',
    'Jeep',
    'Karma',
    'Kia',
    'Lamborghini',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Lotus',
    'Lucid',
    'Maserati',
    'Maybach',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mercury',
    'MINI',
    'Mitsubishi',
    'Nissan',
    'Oldsmobile',
    'Panoz',
    'Plymouth',
    'Polestar',
    'Pontiac',
    'Porsche',
    'Ram',
    'Rivian',
    'Rolls-Royce',
    'Saab',
    'Saturn',
    'Scion',
    'smart',
    'Spyker',
    'Subaru',
    'Suzuki',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ]

  const makeModelArray = [
    {
      make:'ACURA',
      models:[
        'CL',
        'ILX',
        'ILX Hybrid',
        'Integra',
        'MDX',
        'NSX',
        'RDX',
        'RL',
        'RLX',
        'RSX',
        'TL',
        'TLX',
        'TSX',
        'TSX Sport Wagon',
        'ZDX',
      ]
  },
  {
    make:'Alfa Romeo',
    models: [
      '4C',
      'Giulia',
      'Stelvio',
    ]
  },
  {
    make:'Aston Martin',
    models: [
      'DB11',
      'DB7',
      'DB9',
      'DB9 GT',
      'DBS',
      'DBS Superleggera',
      'DBX',
      'Rapide',
      'Rapide AMR',
      'Rapide S',
      'V12 Vanquish',
      'V12 Vantage',
      'V12 Vantage S',
      'V8 Vantage',
      'Vanquish',
      'Vanquish S',
      'Vantage',
      'Virage',
    ]
  },
  {
    make:'AUDI',
    models: [
      'A3',
      'A3 Sportback e-tron',
      'A4',
      'A4 allroad',
      'A5',
      'A6',
      'A6 allroad',
      'A7',
      'A8',
      'allroad',
      'allroad quattro',
      'e-tron',
      'e-tron GT',
      'e-tron S',
      'e-tron S Sportback',
      'e-tron Sportback',
      'Q3',
      'Q4 e-tron',
      'Q4 Sportback e-tron',
      'Q5',
      'Q5 Sportback',
      'Q7',
      'Q8',
      'R8',
      'RS 3',
      'RS 4',
      'RS 5',
      'RS 6',
      'RS 7',
      'RS e-tron GT',
      'RS Q8',
      'S3',
      'S4',
      'S5',
      'S6',
      'S7',
      'S8',
      'SQ5',
      'SQ5 Sportback',
      'SQ7',
      'SQ8',
      'TT',
      'TT RS',
      'TTS',
    ]
  },
  {
    make:'BENTLEY',
    models: [
      'Arnage',
      'Azure',
      'Bentayga',
      'Brooklands',
      'Continental',
      'Continental Flying Spur',
      'Continental Flying Spur Speed',
      'Continental GT',
      'Continental GT Speed',
      'Continental GT Speed Convertible',
      'Continental GT3-R',
      'Continental GTC',
      'Continental GTC Speed',
      'Continental Supersports',
      'Continental Supersports Convertible',
      'Flying Spur',
      'Mulsanne',
      'Supersports Convertible ISR',
    ]
  },
  {
    make:'BMW',
    models: [
      '1 Series',
      '2 Series',
      '2 Series Gran Coupe',
      '3 Series',
      '3 Series Gran Turismo',
      '4 Series',
      '4 Series Gran Coupe',
      '5 Series',
      '5 Series Gran Turismo',
      '6 Series',
      '6 Series Gran Coupe',
      '6 Series Gran Turismo',
      '7 Series',
      '8 Series',
      '8 Series Gran Coupe',
      'ActiveHybrid 5',
      'ActiveHybrid 7',
      'ActiveHybrid X6',
      'Alpina',
      'ALPINA B6 Gran Coupe',
      'ALPINA B7',
      'ALPINA B8 Gran Coupe',
      'ALPINA XB7',
      'i3',
      'i4',
      'i7',
      'i8',
      'iX',
      'X1',
      'X2',
      'X3',
      'X4',
      'X5',
      'X6',
      'X7',
      'XM',
      'Z3',
      'Z4',
      'Z8',
    ]
  },
  {
    make:'Bugatti',
    models: [
      'Veyron 16.4'
    ]
  },
  {
    make:'BUICK',
    models: [
      '1 Series',
      '2 Series',
      '2 Series Gran Coupe',
      '3 Series',
      '3 Series Gran Turismo',
      '4 Series',
      '4 Series Gran Coupe',
      '5 Series',
      '5 Series Gran Turismo',
      '6 Series',
      '6 Series Gran Coupe',
      '6 Series Gran Turismo',
      '7 Series',
      'Cascada',
      'Century',
      'Enclave',
      'Encore',
      'Encore GX',
      'Envision',
      'LaCrosse',
      'LeSabre',
      'Lucerne',
      'Park Avenue',
      'Rainier',
      'Regal',
      'Regal Sportback',
      'Regal TourX',
      'Rendezvous',
      'Terraza',
      'Verano',
    ]
  },
  {
    make:'CADILLAC',
    models: [
      'ATS',
      'ATS Coupe',
      'ATS-V',
      'Catera',
      'CT4',
      'CT4-V Blackwing',
      'CT5',
      'CT5-V Blackwing',
      'CT6',
      'CT6-V',
      'CTS',
      'CTS Coupe',
      'CTS Wagon',
      'CTS-V',
      'CTS-V Coupe',
      'CTS-V Wagon',
      'DeVille',
      'DTS',
      'Eldorado',
      'ELR',
      'Escalade',
      'Escalade ESV',
      'Escalade EXT',
      'Escalade Hybrid',
      'LYRIQ',
      'Seville',
      'SRX',
      'STS',
      'STS-V',
      'XLR',
      'XLR-V',
      'XT4',
      'XT5',
      'XT6',
      'XTS',
    ]
  },
  {
    make:'CHEVROLET',
    models: [
      'Astro',
      'Astro Cargo',
      'Avalanche',
      'Aveo',
      'Black Diamond Avalanche',
      'Blazer',
      'Bolt EUV',
      'Bolt EV',
      'Camaro',
      'Captiva Sport',
      'Cavalier',
      'City Express',
      'Classic',
      'Cobalt',
      'Colorado',
      'Corvette',
      'Cruze',
      'Cruze Limited',
      'Equinox',
      'Express',
      'Express Cargo',
      'HHR',
      'Impala',
      'Impala Limited',
      'Lumina',
      'Malibu',
      'Malibu Classic',
      'Malibu Hybrid',
      'Malibu Limited',
      'Malibu Maxx',
      'Metro',
      'Monte Carlo',
      'Prizm',
      'S-10',
      'Silverado 1500',
      'Silverado 1500 Classic',
      'Silverado 1500 Hybrid',
      'Silverado 1500 LD',
      'Silverado 1500 Limited',
      'Silverado 1500HD',
      'Silverado 1500HD Classic',
      'Silverado 2500',
      'Silverado 2500HD',
      'Silverado 2500HD Classic',
    ]
  },
  {
    make:'CHRYSLER',
    models: [
      '200',
      '300',
      '300M',
      'Aspen',
      'Concorde',
      'Crossfire',
      'LHS',
      'Pacifica',
      'Pacifica Hybrid',
      'Prowler',
      'PT Cruiser',
      'Sebring',
      'Town and Country',
      'Voyager',
    ]
  },
  {
    make:'DAEWOO',
    models: [
      'Lanos',
      'Leganza',
      'Nubira',
    ]
  },
  {
    make:'DODGE',
    models: [
      'Avenger',
      'Caliber',
      'Caravan',
      'Challenger',
      'Charger',
      'Dakota',
      'Dart',
      'Durango',
      'Grand Caravan',
      'Hornet',
      'Intrepid',
      'Journey',
      'Magnum',
      'Neon',
      'Nitro',
      'Ram Cargo',
      'Ram Pickup 1500',
      'Ram Pickup 2500',
      'Ram Pickup 3500',
      'Ram Wagon',
      'Sprinter',
      'Sprinter Cargo',
      'SRT Viper',
      'Stratus',
      'Viper',
    ]
  },
  {
    make:'FERARRI',
    models: [
      '360',
      '550',
      '599',
      '430 Scuderia',
      '456M',
      '458 Italia',
      '488 GTB',
      '488 Spider',
      '575M',
      '612 Scaglietti',
      '812 Superfast',
      'California',
      'California T',
      'Enzo',
      'F12 Berlinetta',
      'F430',
      'FF',
      'GTC4Lusso',
      'Portofino',
      'Superamerica',
    ]
  },
  {
    make:'FIAT',
    models: [
      '500',
      '124 Spider',
      '500e',
      '500L',
      '500X',
    ]
  },
  {
    make:'FISKER',
    models: [
      'Karma'
    ]
  },
  {
    make:'FORD',
    models: [
      'Bronco',
      'Bronco Sport',
      'C-Max Energi',
      'C-Max Hybrid',
      'Crown Victoria',
      'E-Series Van',
      'E-Series Wagon',
      'E-Transit Cargo Van',
      'Econoline Cargo',
      'Econoline Wagon',
      'EcoSport',
      'Edge',
      'Escape',
      'Escape Hybrid',
      'Escape Plug-In Hybrid',
      'Escort',
      'Excursion',
      'Expedition',
      'Expedition EL',
      'Explorer',
      'Explorer Sport',
      'Explorer Sport Trac',
      'F-150',
      'F-150 Heritage',
      'F-150 Lightning',
      'F-250 Super Duty',
      'F-350 Super Duty',
      'F-450 Super Duty',
      'Fiesta',
      'Five Hundred',
      'Flex',
      'Focus',
      'Freestar',
      'Freestyle',
      'Fusion',
      'Fusion Energi',
      'Fusion Hybrid',
      'GT',
      'Maverick',
      'Mustang',
      'Mustang Mach-E',
      'Ranger',
      'Shelby GT350',
      'Shelby GT500',
      'Taurus',
      'Taurus X',
      'Thunderbird',
      'Transit Cargo Van',
      'Transit Connect',
      'Transit Connect Cargo Van',
      'Transit Connect Passenger Wagon',
      'Transit Crew Van',
      'Transit Passenger Van',
      'Transit Van',
      'Transit Wagon',
      'Windstar',
      'Windstar Cargo',
    ]
  },
  {
    make:'GENESIS',
    models: [
      'G70',
      'G80',
      'G90',
      'GV60',
      'GV70',
      'GV80',
    ]
  },
  {
    make:'GMC',
    models: [
      'Acadia',
      'Canyon',
      'Envoy',
      'Envoy XL',
      'Envoy XUV',
      'HUMMER EV',
      'Jimmy',
      'Safari',
      'Safari Cargo',
      'Savana',
      'Savana Cargo',
      'Sierra 1500',
      'Sierra 1500 Classic',
      'Sierra 1500 Hybrid',
      'Sierra 1500 Limited',
      'Sierra 1500HD',
      'Sierra 1500HD Classic',
      'Sierra 2500',
      'Sierra 2500HD',
      'Sierra 2500HD Classic',
      'Sierra 3500',
      'Sierra 3500 Classic',
      'Sierra 3500HD',
      'Sierra C3',
      'Sonoma',
      'Terrain',
      'Yukon',
      'Yukon Hybrid',
      'Yukon XL',
    ]
  },
  {
    make:'HONDA',
    models: [
      'Accord',
      'Accord Crosstour',
      'Accord Hybrid',
      'Accord Plug-In Hybrid',
      'Civic',
      'Clarity',
      'CR-V',
      'CR-V Hybrid',
      'CR-Z',
      'Crosstour',
      'Element',
      'Fit',
      'Fit EV',
      'HR-V',
      'Insight',
      'Odyssey',
      'Passport',
      'Pilot',
      'Prelude',
      'Ridgeline',
      'S2000',
    ]
  },
  {
    make:'HUMMER',
    models: [
      'H1',
      'H1 Alpha',
      'H2',
      'H2 SUT',
      'H3',
      'H3T',
    ]
  },
  {
    make:'HYUNDAI',
    models: [
      'Accent',
      'Azera',
      'Elantra',
      'Elantra Coupe',
      'Elantra GT',
      'Elantra Touring',
      'Entourage',
      'Equus',
      'Genesis',
      'Genesis Coupe',
      'IONIQ 5',
      'Ioniq Electric',
      'Ioniq Hybrid',
      'Ioniq Plug-In Hybrid',
      'Kona',
      'Kona Electric',
      'NEXO',
      'Palisade',
      'Santa Cruz',
      'Santa Fe',
      'Santa Fe Hybrid',
      'Santa Fe Plug-In Hybrid',
      'Santa Fe Sport',
      'Santa Fe XL',
      'Sonata',
      'Sonata Hybrid',
      'Sonata Plug-in Hybrid',
      'Tiburon',
      'Tucson',
      'Tucson Plug-In Hybrid',
      'Veloster',
      'Venue',
      'Veracruz',
      'XG300',
      'XG350',
    ]
  },
  {
    make:'INFINITI',
    models: [
      'EX',
      'FX',
      'G',
      'G Convertible',
      'G Coupe',
      'G Sedan',
      'I',
      'JX',
      'M',
      'Q',
      'Q40',
      'Q50',
      'Q60',
      'Q60 Convertible',
      'Q60 Coupe',
      'Q70',
      'QX',
      'QX30',
      'QX4',
      'QX50',
      'QX55',
      'QX60',
      'QX70',
      'QX80',
    ]
  },
  {
    make:'ISUZU',
    models: [
      'Ascender',
      'Axiom',
      'i-Series',
      'Rodeo',
      'Rodeo Sport',
      'Trooper',
      'VehiCROSS',
    ]
  },
  {
    make:'JAGUAR',
    models: [
      'E-PACE',
      'F-PACE',
      'F-TYPE',
      'I-PACE',
      'S-Type',
      'X-Type',
      'XE',
      'XF',
      'XJ-Series',
      'XK-Series',
    ]
  },
  {
    make:'JEEP',
    models: [
      'Cherokee',
      'Commander',
      'Compass',
      'Gladiator',
      'Grand Cherokee',
      'Grand Cherokee 4xe',
      'Grand Cherokee L',
      'Grand Cherokee WK',
      'Grand Wagoneer',
      'Grand Wagoneer L',
      'Liberty',
      'Patriot',
      'Renegade',
      'Wagoneer',
      'Wagoneer L',
      'Wrangler',
      'Wrangler 4xe',
      'Wrangler JK',
    ]
  },
  {
    make:'KARMA',
    models: [
      'GS-6',
      'Revero GT',
    ]
  },
  {
    make:'KIA',
    models: [
      'Amanti',
      'Borrego',
      'Cadenza',
      'Carnival',
      'EV6',
      'Forte',
      'K5',
      'K900',
      'Niro',
      'Niro EV',
      'Niro Plug-In Hybrid',
      'Optima',
      'Optima Hybrid',
      'Optima Plug-In Hybrid',
      'Rio',
      'Rondo',
      'Sedona',
      'Seltos',
      'Sephia',
      'Sorento',
      'Sorento Hybrid',
      'Sorento Plug-In Hybrid',
      'Soul',
      'Soul EV',
      'Spectra',
      'Sportage',
      'Sportage Hybrid',
      'Sportage Plug-In Hybrid',
      'Stinger',
      'Telluride',
    ]
  },
  {
    make:'LAMBORGHINI',
    models: [
      'Aventador',
      'Diablo',
      'Gallardo',
      'Huracan',
      'Murcielago',
      'Reventon',
      'Urus',
    ]
  },
  {
    make:'LAND ROVER',
    models: [
      'Defender',
      'Discovery',
      'Discovery Sport',
      'Freelander',
      'LR2',
      'LR3',
      'LR4',
      'Range Rover',
      'Range Rover Evoque',
      'Range Rover Sport',
      'Range Rover Velar',
    ]
  },
  {
    make:'LEXUS',
    models: [
      'CT',
      'ES',
      'GS',
      'GX',
      'HS',
      'IS',
      'LC',
      'LFA',
      'LS',
      'LX',
      'NX',
      'RC',
      'RX',
      'SC',
      'UX',
    ]
  },
  {
    make:'LINCOLN',
    models: [
      'Aviator',
      'Blackwood',
      'Continental',
      'Corsair',
      'LS',
      'Mark LT',
      'MKC',
      'MKS',
      'MKT',
      'MKX',
      'MKZ',
      'Nautilus',
      'Navigator',
      'Navigator L',
      'Town Car',
      'Zephyr',
    ]
  },
  {
    make:'LOTUS',
    models: [
      'Elise',
      'Esprit',
      'Evora',
      'Evora 400',
      'Evora GT',
      'Exige',
    ]
  },
  {
    make:'LUCID',
    models: [
      'Air'
    ]
  },
  {
    make:'MASERATI',
    models: [
      'Coupe',
      'Ghibli',
      'GranSport',
      'GranTurismo',
      'GranTurismo Convertible',
      'Levante',
      'MC20',
      'Quattroporte',
      'Spyder',
    ]
  },
  {
    make:'MAYBACH',
    models: [
      '57',
      '62',
      'Landaulet',
    ]
  },
  {
    make:'MAZDA',
    models: [
      '2',
      '3',
      '5',
      '6',
      '626',
      'B-Series Pickup',
      'B-Series Truck',
      'CX-3',
      'CX-30',
      'CX-5',
      'CX-50',
      'CX-7',
      'CX-9',
      'Mazdaspeed 3',
      'Mazdaspeed 6',
      'Mazdaspeed MX-5 Miata',
      'Mazdaspeed Protege',
      'Millenia',
      'MPV',
      'MX-30',
      'MX-5 Miata',
      'MX-5 Miata RF',
      'Protege',
      'Protege5',
      'RX-8',
      'Tribute',
      'Tribute Hybrid',
      'Truck',
    ]
  },
  {
    make:'MCLAREN',
    models: [
      '570GT',
      '570S',
      '570S Spider',
      '600LT',
      '600LT Spider',
      '650S Coupe',
      '650S Spider',
      '675LT Coupe',
      '675LT Spider',
      '720S',
      '720S Spider',
      '765LT',
      '765LT Spider',
      'Artura',
      'Elva',
      'GT',
      'MP4-12C',
      'MP4-12C Spider',
      'Senna',
    ]
  },
  {
    make:'MERCEDES-BENZ',
    models: [
      'A-Class',
      'AMG GT',
      'B-Class Electric Drive',
      'C-Class',
      'CL-Class',
      'CLA-Class',
      'CLK-Class',
      'CLS-Class',
      'E-Class',
      'EQB',
      'EQS',
      'EQS SUV',
      'G-Class',
      'GL-Class',
      'GLA-Class',
      'GLB-Class',
      'GLC-Class',
      'GLC-Class Coupe',
      'GLE-Class',
      'GLE-Class Coupe',
      'GLK-Class',
      'GLS-Class',
      'M-Class',
      'Maybach',
      'Maybach GLS',
      'Metris',
      'R-Class',
      'S-Class',
      'SL-Class',
      'SLC-Class',
      'SLK-Class',
      'SLR McLaren',
      'SLS AMG',
      'SLS AMG GT',
      'SLS AMG GT Final Edition',
      'Sprinter',
      'Sprinter Worker',
    ]
  },
  {
    make:'MERCURY',
    models: [
      'Cougar',
      'Grand Marquis',
      'Marauder',
      'Mariner',
      'Mariner Hybrid',
      'Milan',
      'Milan Hybrid',
      'Montego',
      'Monterey',
      'Mountaineer',
      'Sable',
      'Villager',
    ]
  },
  {
    make:'MINI',
    models: [
      'Clubman',
      'Convertible',
      'Cooper',
      'Cooper Clubman',
      'Cooper Countryman',
      'Cooper Coupe',
      'Cooper Paceman',
      'Cooper Roadster',
      'Countryman',
      'Hardtop 2 Door',
      'Hardtop 4 Door',
    ]
  },
  {
    make:'MITSUBISHI',
    models: [
      'Diamante',
      'Eclipse',
      'Eclipse Cross',
      'Eclipse Spyder',
      'Endeavor',
      'Galant',
      'i-MiEV',
      'Lancer',
      'Lancer Evolution',
      'Lancer Sportback',
      'Mirage',
      'Mirage G4',
      'Montero',
      'Montero Sport',
      'Outlander',
      'Outlander PHEV',
      'Outlander Sport',
      'Raider',
    ]
  },
  {
    make:'NISSAN',
    models: [
      '350Z',
      '370Z',
      'Altima',
      'Altima Hybrid',
      'ARIYA',
      'Armada',
      'Cube',
      'Frontier',
      'GT-R',
      'Juke',
      'Kicks',
      'LEAF',
      'Maxima',
      'Murano',
      'Murano CrossCabriolet',
      'NV Cargo',
      'NV Passenger',
      'NV200',
      'Pathfinder',
      'Quest',
      'Rogue',
      'Rogue Select',
      'Rogue Sport',
      'Sentra',
      'Titan',
      'Titan XD',
      'Versa',
      'Versa Note',
      'Xterra',
      'Z',
    ]
  },
  {
    make:'OLDSMOBILE',
    models: [
      'Alero',
      'Aurora',
      'Bravada',
      'Intrigue',
      'Silhouette',
    ]
  },
  {
    make:'PLYMOUTH',
    models: [
      'Prowler',
      'Neon'
    ]
  },
  {
    make:'POLESTAR',
    models: [
      '1',
      '2'
    ]
  },
  {
    make:'PONTIAC',
    models: [
      'Aztek',
      'Bonneville',
      'Firebird',
      'G3',
      'G5',
      'G6',
      'G8',
      'Grand Am',
      'Grand Prix',
      'GTO',
      'Montana',
      'Montana SV6',
      'Solstice',
      'Sunfire',
      'Torrent',
      'Vibe',
    ]
  },
  {
    make:'PORSCHE',
    models: [
      '911',
      '718 Boxster',
      '718 Cayman',
      '918 Spyder',
      'Boxster',
      'Carrera GT',
      'Cayenne',
      'Cayenne Coupe',
      'Cayman',
      'Macan',
      'Panamera',
      'Taycan',
    ]
  },
  {
    make:'RAM',
    models: [
      '1500',
      '1500 Classic',
      '2500',
      '3500',
      'Dakota',
      'Promaster Cargo Van',
      'Promaster City',
      'Promaster Window Van',
    ]
  },
  {
    make:'RIVIAN',
    models: [
      'R1T'
    ]
  },
  {
    make:'ROLLS-ROYCE',
    models: [
      'Corniche',
      'Cullinan',
      'Dawn',
      'Ghost',
      'Park Ward',
      'Phantom',
      'Phantom Coupe',
      'Phantom Drophead Coupe',
      'Silver Seraph',
      'Wraith',
    ]
  },
  {
    make:'SAAB',
    models: [
      '9-3',
      '9-2X',
      '9-3 Griffin',
      '9-4X',
      '9-7X',
    ]
  },
  {
    make:'SATURN',
    models: [
      'Astra',
      'Aura',
      'Aura Hybrid',
      'ION',
      'L-Series',
      'Outlook',
      'Relay',
      'S-Series',
      'Sky',
      'VUE',
      'VUE Hybrid',
    ]
  },
  {
    make:'SCION',
    models: [
      'FR-S',
      'iA',
      'iM',
      'iQ',
      'tC',
      'xA',
      'xB',
      'xD',
    ]
  },
  {
    make:'SMART',
    models: [
      'EQ fortwo',
      'fortwo',
    ]
  },
  {
    make:'SUBARU',
    models: [
      'Ascent',
      'B9 Tribeca',
      'Baja',
      'BRZ',
      'Crosstrek',
      'Forester',
      'Impreza',
      'Impreza WRX',
      'Legacy',
      'Outback',
      'Solterra',
      'Tribeca',
      'WRX',
      'XV Crosstrek',
    ]
  },
  {
    make:'SUZUKI',
    models: [
      'Aerio',
      'Equator',
      'Esteem',
      'Forenza',
      'Grand Vitara',
      'Kizashi',
      'Reno',
      'Swift',
      'SX4',
      'Verona',
      'Vitara',
      'XL-7',
      'XL7',
    ]
  },
  {
    make:'TESLA',
    models: [
      'Model 3',
      'Model S',
      'Model X',
      'Model Y',
      'Roadster',
    ]
  },
  {
    make:'TOYOTA',
    models: [
      '86',
      '4Runner',
      'Avalon',
      'Avalon Hybrid',
      'bZ4X',
      'C-HR',
      'Camry',
      'Camry Hybrid',
      'Camry Solara',
      'Celica',
      'Corolla',
      'Corolla Cross',
      'Corolla Hatchback',
      'Corolla Hybrid',
      'Corolla iM',
      'Crown',
      'ECHO',
      'FJ Cruiser',
      'GR Corolla',
      'GR Supra',
      'GR86',
      'Highlander',
      'Highlander Hybrid',
      'Land Cruiser',
      'Matrix',
      'Mirai',
      'MR2 Spyder',
      'Prius',
      'Prius c',
      'Prius Plug-in',
      'Prius Prime',
      'Prius v',
      'RAV4',
      'RAV4 EV',
      'RAV4 Hybrid',
      'RAV4 Prime',
      'Sequoia',
      'Sienna',
      'Tacoma',
      'Tundra',
      'Venza',
      'Yaris',
      'Yaris Hatchback',
      'Yaris iA',
    ]
  },
  {
    make:'VOLKSWAGEN',
    models: [
      'Arteon',
      'Atlas',
      'Atlas Cross Sport',
      'Beetle',
      'Beetle Convertible',
      'Cabrio',
      'CC',
      'e-Golf',
      'Eos',
      'EuroVan',
      'Golf',
      'Golf Alltrack',
      'Golf GTI',
      'Golf R',
      'Golf R32',
      'Golf SportWagen',
      'ID.4',
      'Jetta',
      'Jetta GLI',
      'Jetta Hybrid',
      'Jetta SportWagen',
      'New Beetle',
      'Passat',
      'Phaeton',
      'Rabbit',
      'Rabbit GTI',
      'Routan',
      'Taos',
      'Tiguan',
      'Tiguan Limited',
      'Touareg',
    ]
  },
  {
    make:'VOLVO',
    models: [
      'C30',
      'C40 Recharge Twin Pure Electric',
      'C70',
      'S40',
      'S60',
      'S60 Cross Country',
      'S80',
      'S90',
      'V40',
      'V50',
      'V60',
      'V60 Cross Country',
      'V70',
      'V90',
      'V90 Cross Country',
      'XC',
      'XC40',
      'XC40 Recharge',
      'XC60',
      'XC70',
      'XC90',
    ]
  },

  ]

  const bodyStylesArray = [
    'Car',
    'Van',
    'SUV',
    'Pickup Truck',
  ]

  const bodySpecificStylesArray = [
    'SUV',
    'Wagon',
    'Sedan',
    'Ext Van',
    'Van',
    'Hatchback',
    'Truck (Quad Cab)',
    'Truck (Mega Cab)',
    'Truck (Regular Cab)',
    'Truck (Crew Cab)',
    'Minivan',
    'Truck (King Cab)',
    'Convertible',
    'Truck (SuperCrew)',
    'Coupe',
    'Truck (Extended Cab)',
    'Truck (Double Cab)',
    'Truck (SuperCab)',
    'Cargo Van',
    'Passenger Van',
    'Truck (CrewMax)',
    'Truck (Access Cab)',
    'Truck (Club Cab)',
    'Truck (Cab Plus)',
    'Truck (Xtracab)',
  ]

  const doorsArray = [
    '2',
    '4+',
  ]

  const seatsArray = [
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8+'
  ]

  const cylindersArray = [
    'Rotary',
    '2',
    '3',
    '4',
    '5',
    '6',
    '8',
    '10',
    '12'
  ]
  
  const transmissionArray = [
      'Automatic',
      'Manual'
    ]

  const driveTrainArray = [
    'Front Wheel Drive',
    'Rear Wheel Drive',
    'All Wheel Drive',
    'Four Wheel Drive'
  ]

  const fuelTypeArray = [
    'Gas',
    'Diesel',
    'Hybrid',
    'Electric'
  ]

  const countryOriginArray = [
    'Germany',
    'United States',
    'United Kingdom',
    'Japan',
    'Italy',
    'South Korea',
    'Sweden',
    'France',
    'Netherlands'
  ]

  const allYears = Array.from({ length: 2023 - 2001 + 1 }, (_, index) => 2001 + index);
  const yearsArray = selectedMinYear ? allYears.filter((year) => year >= selectedMinYear) : allYears;

  const handleMinYearChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setSelectedMinYear(selectedValue);
    updateVehicleOptions('minYear', e.target.value);
  };

  const handleMakeChange = (e) => {
    setVehicleInfo({...vehicleInfo, make: e.target.value})
    updateVehicleOptions('make', e.target.value);
  }

  const toolTipHandler = () => {
    setToolTip(false)
  }

  const makeModelResults = makeModelArray.filter(make => 
    make.make === vehicleInfo.make
    )

  return (
    <div className='vehicle-db-search'>
      {toolTip &&
        <Zoom>

       <span className='vehicle-db-tool-tip'>
        <h3>Search Tip</h3>
        <ul>
            <li>Search as many or as few fields as you want.</li>
        </ul>
        <span className='vehicle-db-close-btn' onClick={toolTipHandler}>X</span>
      </span>
      </Zoom>

      }
      <div className='margin-15 year-module'>
        <p>Year Selection</p>
        <select onChange={handleMinYearChange}>          
        <option>Select Min Year</option>
        {allYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
        </select>
        <select onChange={(e) => {
            updateVehicleOptions('maxYear', e.target.value)
        }}>
          <option>Select Max Year</option>
            {yearsArray.map((year) => {
                return(
                    <option value={year}>{year}</option>
                )
            })}
        </select>
      </div>
      <div className='margin-15 make-model-module' >
        <p>Make and Model Selection</p>
        <select onChange={(e) => {
            handleMakeChange(e)
            }}
        >
          <option value="Select a Make">Select Make</option>
          {makeModelArray.map(make => 
          <option value={make.make}>{make.make}</option>
          )}
        </select>
        <select onChange={(e) => {
                updateVehicleOptions('model', e.target.value);
            }}>
          <option value="Select a Model">Select Model</option>
          {makeModelResults.map((model) => 
              model.models.map(filteredModel => 
              <option value={filteredModel}>{filteredModel}</option>
              )
          ) }
        </select>
      </div>
      <div className='margin-15 price-module' >
        <p>Price Selection</p>
        Min MSRP $
        <input onChange={(e) => {
            updateVehicleOptions('minPrice', e.target.value)
        }}></input>
        Max MSRP $
        <input onChange={(e) => {
            updateVehicleOptions('maxPrice', e.target.value)
        }}></input>
      </div> 
      <div className='margin-15 body-style-module' >
        <p>Select Vehicle Type</p>
          <select onChange={(e)  => {
            updateVehicleOptions('body', e.target.value)
          }}>
            <option>Select Type</option>
            ({bodyStylesArray.map((body) => { 
              return (
              <option>{body}</option>
            )
            })})
          </select>
        <p>Select Body Style</p>
          <select onChange={(e)  => {
            updateVehicleOptions('bodySpecific', e.target.value)
          }}>
            <option>Select a Body style</option>
            ({bodySpecificStylesArray.map((bodySpecific) => { 
              return (
              <option>{bodySpecific}</option>
            )
            })})
          </select>
          <select onChange={(e)  => {
            updateVehicleOptions('doors', e.target.value)
          }}>
            <option>Select Doors</option>
            ({doorsArray.map((door) => { 
              return (
              <option>{door}</option>
            )
            })})
          </select>
          <select onChange={(e)  => {
            console.log(e.target.value)
            updateVehicleOptions('seats', e.target.value)
          }}>
            <option>Select Seats</option>
            ({seatsArray.map((seat) => { 
              return (
              <option>{seat}</option>
            )
            })})
          </select>
        </div>
        <div className='margin-15 vehicle-options-module' >
        <p>Select Vehicle Options</p>
          {/* <select onChange={(e) => {
            updateVehicleOptions('cylinders', e.target.value)
          }}>
            <option>Select Cylinders</option>
            ({cylindersArray.map((cylinder) => { 
              return (
              <option>{cylinder}</option>
            )
            })})
          </select> */}
          <select onChange={(e) => {
            updateVehicleOptions('transmission', e.target.value)
          }}>
            <option>Select Transmission</option>
            ({transmissionArray.map((transmission) => { 
              return (
              <option>{transmission}</option>
            )
            })})
          </select>
          <select onChange={(e) => {
            updateVehicleOptions('drivetrain', e.target.value)
          }}>
            <option>Select Drivetrain</option>
            ({driveTrainArray.map((drivetrain) => { 
              return (
              <option>{drivetrain}</option>
            )
            })})
          </select>
          <select onChange={(e) => {
            updateVehicleOptions('manufacturerCountry', e.target.value)
          }}>
            <option>Select Country</option>
            ({countryOriginArray.map((country) => { 
              return (
              <option>{country}</option>
            )
            })})
          </select>
        </div>
        <div className='margin-15 fuel-module' >
        <p>Fuel Selection</p>
        <select onChange={(e) => {
            updateVehicleOptions('fuelType', e.target.value)
          }}>
            <option>Select Fuel Type</option>
            ({fuelTypeArray.map((fuel) => { 
              return (
              <option>{fuel}</option>
            )
            })})
          </select>
          Enter Min MPG
          <input onChange={(e) => {
            updateVehicleOptions('fuelMpg', e.target.value)
            }}></input>
        </div>
        <button onClick={() => updateSearch()} >Update</button>
    </div>
  )
}

export default DatabaseSearch;