import { useState, useContext } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import Slider from "../../input/input";

const VehicleMaintenance = () => {

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    const updateValue = (newValue) => {
        setVehicleObject({...vehicleObject, maintenanceRating: newValue})
    }

    return (
        <div className="slider">
            <h2>Select Preferred Annual Maintenance Costs</h2>
            <Slider className={'slider-parent'} onValueChange={updateValue} value={vehicleObject.maintenanceRating} lowMsg="low maintenace" highMsg="high maintenance" />
        </div>
    );
  };
  
  export default VehicleMaintenance;