import { Router, Route, Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import axios from 'axios';
import validator from 'validator'
import { Fade, Bounce, Zoom } from 'react-reveal';

const Footer = () => {

        
    const [email, setEmail] = useState('')
    const [subscribeMessage, setSubscribeMessage] = useState(false)

    const updateEmail = (e) => {
        setEmail(e.target.value);
      };

    const subscribeFunction = () => {
        if(validator.isEmail(email)){
        axios.post('https://vehicle-database-selector.herokuapp.com/subscribe', {
            email: email,
        })
        .then((res) => {
            setEmail('')
            setSubscribeMessage(true)
            hideSubscribeMessage()
        })
        .catch(err => {
            console.log('axios/heroku call error')
            console.error(err.response.data);    // ***
            console.error(err.response.status);  // ***
        })
    } else {
        alert('Please enter a valid email address')
    }
}

    const hideSubscribeMessage = () => {     

    setTimeout(() => {
        setSubscribeMessage(false);
      }, 3500);
    }

    const SubscribeMessage = () => {

        return (
            <Fade>
                <div className='subscribe-message'>
                    <p>You're <br/> Subscribed!</p>
                </div>
            </Fade>

        )
    }

    return (
        <div className='footer'>
        <div>
            <ul>
                <li className='link-header'>Connect</li>
                <li><Link className='link-white-no-padding' to="/contactUs">Contact Us</Link></li>
                {/* <li><a target="_blank" href="https://www.instagram.com/vehiclematchmaker/">Find us on Facebook</a></li>                                                     */}
                <li><a target="_blank" href="https://twitter.com/vehicleselector">Follow us on Twitter</a></li>                                                    
                <li><a target="_blank" href="https://www.instagram.com/vehiclematchmaker">Follow us on Instagram</a></li>                                                    
            </ul>
        </div>
        <div>
            <ul>
                <li className='link-header'>Company</li>
                <li><Link className='link-white-no-padding' to="/aboutUs">About Us</Link></li>
                <li><Link className='link-white-no-padding' to="/ourStory">Our Story</Link></li>
            </ul>
        </div>
        <div>
            <ul>
                <li className='link-header'>Services</li>
                <li><Link className='link-white-no-padding' to="/card">Vehicle Selector</Link></li>
                <li><Link className='link-white-no-padding' to="/purchasingTips">Purchasing Tips</Link></li>
                <li><Link className='link-white-no-padding' to="/purchasingTips">Pre Purchase Checklist</Link></li>
                {/* <li><Link className='link-white-no-padding' to="/blog">Our Blog</Link></li> */}
                <li><Link className='link-white-no-padding' to="/upcomingProjects">Upcoming Projects</Link></li>
            </ul>
        </div>
        <div>
            <ul>
                <li className='link-header'>Get Help</li>
                <li><Link className='link-white-no-padding' to="/faqs">FAQs</Link></li>
                {/* <li><Link className='link-white-no-padding' to="/accessibility">Accessibility</Link></li> */}
                {/* <li><Link className='link-white-no-padding' to="/siteMap">Site Map</Link></li> */}
                <li><Link className='link-white-no-padding' to="/termsOfUse">Terms of Use</Link></li>
                <li><Link className='link-white-no-padding'to="/privacyPolicy">Privacy Policy</Link></li>
            </ul>
        </div>
        <div className='subscribe-container'>
            {subscribeMessage && <SubscribeMessage />}
            <div>
                <b>Subscribe to our newsletter</b>
            </div>
            <div>
                <input onChange={updateEmail} value={email} placeholder='Enter your email address' />
            </div>
            <button onClick={subscribeFunction} className='subscribe-btn'>Subscribe</button>
        </div>
    </div> 
    )
}

export default Footer;