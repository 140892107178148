import React, { useState, useContext, useEffect } from "react";
import CheckboxTile from "../../checkbox/checkbox.component";
import { bodyArray } from "../../../data/vehicleOptions";
import ValidationMessage from "../../validation/validation.component";
import { VehicleContext } from "../../use context/vehicle-object.context";

const VehicleType = () => {

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    return(
        <>
        <h2>* Select Preferred Vehicle Category</h2>
        <ValidationMessage message={'Please select at least one vehicle category'} />
        {bodyArray.map((type) => {
            return(
            <CheckboxTile key={type} label={type} value={type} />
            )
        })}
        </>
    )
}

export default VehicleType;