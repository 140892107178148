import React from 'react';
import HeaderText from "../../header text/header-text.component";
import './shop-map.style.scss';

const EngineTuneUp = () => {

  return (
    <div style={{color:'white'}}>
        <div className="faq-header">
            <HeaderText text='How to Perform a Tune Up' />
        </div>
        <div className="diy-instructions-container">

            <h3>Gather the Necessary Tools and Parts:</h3>
            <ul>
                <li>Basic hand tools (such as wrenches, sockets, and pliers)</li>
                <li>Spark plugs (ensure they match your vehicle's specifications)</li>
                <li>Air filter</li>
                <li>Fuel filter (if applicable)</li>
                <li>PCV valve (if applicable)</li>
                <li>Ignition components (if necessary, such as distributor cap, rotor, or ignition wires)</li>
            </ul>
            <h3>Park and Prepare Your Vehicle:</h3>
            <ul>
                <li>Park your vehicle in a well-ventilated and safe location.</li>
                <li>Engage the parking brake and turn off the engine.</li>
                <li>Allow the engine to cool down before starting any work.</li>
            </ul>
            <h3>Replace Spark Plugs:</h3>
            <ul>
                <li>Locate the spark plugs (typically found on the engine cylinder head).</li>
                <li>Remove one spark plug wire at a time by gripping the boot (not the wire) and gently twisting and pulling it.</li>
                <li>Use a spark plug socket and ratchet to remove the old spark plug.</li>
                <li>Install a new spark plug, hand-tightening it first and then using the socket to secure it snugly (avoid overtightening).</li>
                <li>Reattach the spark plug wire to the corresponding spark plug, ensuring a firm connection.</li>
                <li>Repeat this process for each spark plug, handling them one at a time to avoid mixing up the wires.</li>
            </ul>
            <h3>Replace the Air Filter:</h3>
            <ul>
                <li>Locate the air filter housing (typically a black plastic box with metal clips on the side).</li>
                <li>Open the air filter housing and remove the old air filter.</li>
                <li>Install the new air filter, ensuring it is properly seated in the housing.</li>
                <li>Close the air filter housing and secure the metal clips.</li>
            </ul>
            <h3>Replace the Fuel Filter:</h3>
            <ul>
                <li>Locate the fuel filter (typically found along the fuel line).</li>
                <li>Use a wrench to loosen the fuel line fittings and remove the old fuel filter.</li>
                <li>Install the new fuel filter, ensuring it is properly seated and the fittings are tightened securely.</li>
            </ul>
            <h3>Replace the PCV Valve:</h3>
            <ul>
                <li>Locate the PCV valve (typically found on the valve cover or intake manifold).</li>
                <li>Remove the old PCV valve and install the new one, ensuring it is properly seated.</li>
            </ul>
            <h3>Check Ignition Components (if applicable):</h3>
            <ul>
                <li>If your vehicle has a distributor cap, rotor, or ignition wires, visually inspect them for signs of wear, corrosion, or damage.</li>
                <li>Replace any worn or damaged components following the instructions provided in your vehicle's manual.</li>
            </ul>
            <h3>Inspect Belts and Hoses:</h3>
            <ul>
                <li>Visually inspect the belts and hoses under the hood for cracks, fraying, or other signs of wear.</li>
                <li>Replace any damaged or worn belts and hoses as necessary.</li>
            </ul>
            <h3>Check Fluid Levels:</h3>
            <ul>
                <li>Check the engine oil level using the dipstick and top up if necessary.</li>
                <li>Inspect other fluid levels, including coolant, brake fluid, power steering fluid, and windshield washer fluid. Add fluids as needed.</li>
            </ul>
            <h3>Reassemble and Test:</h3>
            <ul>
                <li>Reattach any components that were removed during the tune up process.</li>
                <li>Start the engine and check for any abnormal noises or leaks.</li>
                <li>Take the vehicle for a test drive to ensure everything is functioning properly.</li>
            </ul>
            <h3>Dispose of Old Parts and Fluids:</h3>
            <ul>
                <li>Properly dispose of any old parts and fluids according to local regulations.</li>
            </ul>

            <h3>Congratulations! You have completed a basic engine tune-up. </h3>

        </div>

    </div>
    );
}

export default EngineTuneUp;

