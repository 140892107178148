import { ThreeDots } from  'react-loader-spinner'
import React, { useState } from 'react';

const Loader = () => {

  const [lightMode, setLightMode] = useState(false)

    return (
      <div className='loader-container'>
        {/* <h1>Finding Your Perfect Match</h1> */}
        <div className='loader'>
          <ThreeDots 
            height="100" 
            width="30%" 
            radius="9"
            color={lightMode ? "black" : "white"}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      </div>
      )
    }

    export default Loader;