import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState,useEffect } from "react";
import { VehicleContext } from "../use context/vehicle-object.context";
import Card from '../card/card.component';
import HomePage2 from "../routes/homePage/homePage.component";
import NavBar from '../navbar/navbar.componet';
import HomePage from '../routes/index/index.component';
import AboutUs from "../routes/footer/about us/about-us.component";
import Accessibility from "../routes/footer/accessibility/accessibility.component";
import Blog from "../routes/footer/blog/blog.component";
import ContactUs from "../routes/footer/contact us/contact-us.component";
import Faqs from "../routes/footer/faqs/faqs.component";
import OurStory from "../routes/footer/our story/our-story.component";
import PrivacyPolicy from "../routes/footer/privacy policy/privacy-policy.component";
import PurchasingTips from "../routes/footer/purchasing tips/purchasing-tips.component";
import SiteMap from "../routes/footer/site map/site-map.component";
import TermsOfUse from "../routes/footer/terms of use/terms-of-use.component";
import UpcomingProjects from "../routes/footer/upcoming projects/upcoming-projects.component";
import ShopMap from "../routes/shop map/shop-map.component";

import './selector.style.scss';
import VehicleDatabaseTool from "../routes/database tool/vehicleDatabase.component";

const SelectorMainPage = () => {

    const [validationMessage, setValidationMessage] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 767) {
          setMobileView(true)
        } else if (window.innerWidth >= 767) {
          setMobileView(false)
          // setHideVehicleCards(!hideVehicleCards)
        }
      }
  
      useEffect(() => {
        handleResize();
    }, [])
      
      useEffect(() => {
        window.addEventListener("resize", handleResize)
      })

    const [vehicleObject, setVehicleObject] = useState({
        "activities": [],
        "brandAwareness": 5,
        "cargoRating": [],
        "category": [],
        "categoryScore1": [
            {label: '1', value: 'Reliability'},
            {label: '2', value: 'On a Budget'},
            {label: '3', value: 'Safety'},
            {label: '4', value: 'Luxury'},
        ],
        "categoryScore2": [
            {label: '1', value: 'Cargo Space'},
            {label: '2', value: 'Activities'},
            {label: '3', value: 'Driving Conditions'},
            {label: '4', value: 'Fuel Economy'},
        ],
        "children": [],
        "conditions": [],
        "depreciation": 5,
        "drivetrain": [],
        "fuel": [],
        "fuelEfficiency": 5,
        "luxuryRating": 5,
        "maintenanceRating": 5,
        "maxPrice": 45000,
        "maxTowWeight": '',
        "maxYear": 2020,
        "minPrice": 15000,
        "minYear": 2010,
        "originCountry": [],
        "range": 200,
        "safetyRating": 5,
        "seating": [],
        "sportRating": 5,
        "subCategory": [],
        "towing": [],
        "transmission": [],
    });

    return(

    <BrowserRouter>
        <VehicleContext.Provider value={{vehicleObject, setVehicleObject, validationMessage, setValidationMessage, mobileView, setMobileView}}>
        <Routes>
            <Route exact path='/' element={<HomePage2 />}></Route>
            <Route exact path='/card' element={<Card />}></Route>
            <Route exact path='/shopMap' element={<ShopMap />}></Route>
            <Route exact path='/vehicleDatabaseTool' element={<VehicleDatabaseTool />}></Route>
            <Route exact path='/aboutUs' element={<AboutUs />}></Route>
            <Route exact path='/accessibility' element={<Accessibility />}></Route>
            <Route exact path='/blog' element={<Blog />}></Route>
            <Route exact path='/contactUs' element={<ContactUs />}></Route>
            <Route exact path='/faqs' element={<Faqs />}></Route>
            <Route exact path='/ourStory' element={<OurStory />}></Route>
            <Route exact path='/privacyPolicy' element={<PrivacyPolicy />}></Route>
            <Route exact path='/purchasingTips' element={<PurchasingTips />}></Route>
            <Route exact path='/siteMap' element={<SiteMap />}></Route>
            <Route exact path='/termsOfUse' element={<TermsOfUse />}></Route>
            <Route exact path='/upcomingProjects' element={<UpcomingProjects />}></Route>
        </Routes>
        </VehicleContext.Provider>
      </BrowserRouter>

    )
}

export default SelectorMainPage;