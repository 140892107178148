import { useState, useContext, useEffect } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import './vehicle-category-score.style.scss'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const VehicleCategoryScore1 = ({ setShowNextBtn }) => {

    useEffect(() => {
        setShowNextBtn()
    }, [])

    const optionsArray = [
        {label: '1', value: 'Cargo Space'},
        {label: '2', value: 'Activities'},
        {label: '3', value: 'Driving Conditions'},
        {label: '4', value: 'Fuel Economy'},
    ]

    const [optionItems, setOptionItems] = useState(optionsArray);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(optionItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setOptionItems(items);
        setVehicleObject({...vehicleObject, categoryScore2: items})
    }

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    const updateValue = (newValue) => {
        setVehicleObject({...vehicleObject, brandAwareness: newValue})
    }

    return(
        <div className='order-options-container'>
            <h2>Order Most Important to Least Important</h2>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                    {provided => (
                        <div className='options-container' {...provided.droppableProps} ref={provided.innerRef}>
                            {optionItems.map((option, index) => {
                                return(
                                    <Draggable key={option.label} draggableId={option.label} index={index}>
                                        {provided => (
                                        <div className='option' {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <div>
                                                <span className="category-index">{index+1}</span>
                                            </div>
                                            <p>{option.value}</p>
                                        </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}                                               
                </Droppable>
            </DragDropContext>         
            </div>
    )
}

export default VehicleCategoryScore1;