import { useState, useContext, useEffect } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import Slider from "../../input/input";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import './vehicle-price.style.scss'

const VehiclePrice = () => {
    
    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)
    const [maxPriceMsg, setMaxPriceMsg] = useState(false)

    const [dualRange, setDualRange] = useState({ min: vehicleObject.minPrice, max: vehicleObject.maxPrice });

    const updateValue = (newValue) => {
        setVehicleObject({
          ...vehicleObject,
          minPrice: dualRange.min-250,
          maxPrice: dualRange.max+250
        });
      };
    useEffect(() => {
      dualRange.max >= 100000 ? setMaxPriceMsg(true) : setMaxPriceMsg(false)
    }, [dualRange])
    
      
        
    return (
        <div className="price-container">
            <h2>Select Price Range</h2>
            <div className="dual-slider">
                <InputRange
                    step={250}
                    minValue={5000}
                    maxValue={100000}
                    value={dualRange}
                    onChange={(value) => {
                        setDualRange(value);
                        updateValue(value);
                    }}
                    formatLabel={value => `$${value.toLocaleString()}`}
                    draggableTrack
                />
                <div className="minMaxValue-container">
                    <div className="minMaxValue">
                        <div>Min</div>
                        ${dualRange.min.toLocaleString()}
                    </div>
                    <div className="minMaxValue">
                        <div>Max</div>
                        ${dualRange.max.toLocaleString()}
                        {maxPriceMsg ? "+" : null}
                    </div>
                </div>
            </div>
        </div>
    );
  };

export default VehiclePrice;