import CheckboxTile from '../../checkbox/checkbox.component';
import { activitiesArray } from '../../../data/vehicleOptions';
import ValidationMessage from '../../validation/validation.component';

const VehicleActivities = () => {
 
    return (
        <>
        <h2>Select Any Activities You Do</h2>
        <ValidationMessage message={''} />
        {activitiesArray.map((type) => {
            return(
            <CheckboxTile key={type} label={type} value={type} />
            )
        })}

        </>
    )
}

export default VehicleActivities;