import { useState, useContext } from "react";
import { VehicleContext } from "../use context/vehicle-object.context";

const ValidationMessage = ({ message }) => {

    const {validationMessage, setValidationMessage} = useContext(VehicleContext)

  return (
    <div className="validation-container">
        {validationMessage &&
        <div className="validation-message">
            {message}
        </div>}
    </div>
  );
}

export default ValidationMessage;