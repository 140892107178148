import { useEffect, useState, useContext } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import NavBar from '../../navbar/navbar.componet';
import Footer from '../../footer/footer.component';
import companyName from '../../../data/companyName';
import { Router, Route, Link } from 'react-router-dom'
import { Fade, Bounce, Zoom } from 'react-reveal';
import './homePage.style.scss';
import '../../../index.scss';

const HomePage2 = () => {

    const { mobileView } = useContext(VehicleContext)
    const [mapImg, setMapImg] = useState(require('../../images/mapGif2.gif'));

    useEffect(() => {
        setTimeout(() => {
            setMapImg(require('../../images/mapGifStill.png'))
        }, 9000);
    }, [])

    return (
        <div className='homePage-container'>
            <NavBar mobileScreen={mobileView} />     
            <div className='homePage-div1'>
                <div className='homePage-slogan'>
                    <h3>Simple Tools</h3>
                    <h3>For The Non Car Person</h3>
                    <div className='homePage-slogan-p'>
                        <div>
                            <p>Find the real cost to repair your vehicle.</p>
                            <Link className='homePage-link-btn homePage-link-btn2' >Get a Quote</Link>
                        </div>
                        <div>
                            <p>Find the perfect vehicle for your lifestyle and budget in 5 minutes.</p>
                            <Link className='homePage-link-btn homePage-link-btn1' to="/card">Take The Quiz</Link>
                        </div>
                        <div>
                            <p>Find the most trusted shops in your area.</p>
                            <Link className='homePage-link-btn homePage-link-btn3' to="/shopMap" >Find a Shop</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='homePage-div2'>
                <h3 className={mobileView ? 'thin-text div2-header font-22' : 'thin-text div2-header font-22'}>What is GetMyCar?</h3>
                <p className={mobileView ? 'spaced-out-text font-18 bold-text font-white div2-text' : 'spaced-out-text font-26 bold-text font-white div2-text'}>
                    GetMyCar is your one stop shop for everything automotive. We are the leading provider in vehicle repair estimates and offer the only tool on the internet which matches your lifestyle and budget to the perfect vehicle.
                </p>
            </div>
            <div className='homePage-div3'>
                <p className={mobileView ? 'font-24 div3-header-mobile' : 'font-55 div3-header'}>Transforming The Automotive Industry</p>
                <Fade bottom>
                <p className={mobileView ? 'spaced-out-text font-18 bold-text div3-text' : 'spaced-out-text font-22 bold-text div3-text'}>
                    <span className='highlighted-background'></span> 
                    <span>Bringing <p class="simple-highlight">transparency</p> to the industry with cutting edge technology. A suite of tools which allow consumers to find the best vehicle match for their lifestyle, get estimates on repairs and find the best reviewed shops nearby.</span>
                    <span>No more shopping around or scouring the internet for the best deal.</span>
                    <span>Use our tools to help you find the perfect match, whether it be a new vehicle or a repair shop.</span>
                </p>
                </Fade>
            </div>
            <div className='homePage-div4'>
                <div className='homePage-div4a'>
                    <Fade bottom>
                        <img className={mobileView ? 'macbook-img-mobile' : 'macbook-img'} src={require('../../images/laptopImage.png')} />
                    </Fade>
                </div>
                <div className='homePage-div4b'>
                    <p className={mobileView ? 'font-24 font-white margin-10' :'font-55 font-white margin-50'}>
                        <i>Skip The Endless Search</i>
                    </p>
                    <p className='spaced-out-text font-white font-18 margin-20'>
                        What if 5 minutes could save you 18 hours! Answer our 22 questions and save yourself the industry average of 18 hours of research when preparing to make a new car purchase.
                    </p>
                </div>
            </div>
            <div className='homePage-div5'>
                <div className='homePage-div5a'>
                    <h5 className='top-text-header'>Vehicle Selector</h5>
                    <h4 className={mobileView ? 'font-24 margin-10' :'font-55 margin-50'}>
                        <span>Stop</span>Guessing
                    </h4>
                    <p className='spaced-out-text-less font-18 margin-100'>
                        <span>Answer a few quick questions and get the results you need.</span>
                        <span>Without having access to all of the vehicles, how do you know which car is right for you? Quickly access our propietary database of<p class="simple-highlight">every</p>vehicle, not just the ones currently for sale.</span>
                    </p>
                </div>
                <div className='homePage-div5b'>
                        <img className={mobileView ? 'think-img-mobile' : 'think-img'} src={require('../../images/think2.png')} />
                </div>
            </div>    
            <div className='homePage-div5'>
                <div className='homePage-div5c'>
                    <h5 className='top-text-header'>Find Repair Shops</h5>
                    <h4 className={mobileView ? 'font-24 margin-10' :'font-55 margin-50'}>
                        <span>Stop</span>Searching
                    </h4>
                    <p className='spaced-out-text-less font-18 margin-75'>
                        <span>No more hunting for <p class="simple-highlight">"good enough"</p>.</span>
                        <span>Our comprehensive database allows individuals to search for repair shops based on location, service specialties, and customer reviews, ensuring a hassle-free experience in locating trusted automotive experts.</span>
                    </p>
                </div>
                <div className='homePage-div5d'>
                        <img className={mobileView ? 'think-img-mobile' : 'map-img'} src={mapImg} />
                </div>
            </div>    
            <div className='homePage-div5'>
                <div className='homePage-div5e'>
                    <h5 className='top-text-header'>Get Repair Quotes</h5>
                    <h4 className={mobileView ? 'font-24 margin-10' :'font-55 margin-50'}>
                        <span>Stop</span>Overspending
                    </h4>
                    <p className='spaced-out-text-less font-18 margin-75'>
                        <span>Tell us what's wrong with your car, and we'll tell you how much you <p class="simple-highlight">should</p> pay.</span>
                        <span>For decades, vehicle owners have been left in the dark when it comes to the cost of parts, labour and "shop supplies". At GetMyCar, we remove all of the doubt and inject data, technology and confidence into your search for the right repair shop.</span>
                    </p>
                </div>
                <div className='homePage-div5f'>
                    <Fade left>
                            <img className={mobileView ? 'wallet-img-mobile' : 'wallet-img'} src={require('../../images/dollarSign.png')} />
                    </Fade>
                </div>
            </div>    
            {/* <div className='homePage-div5'>
                <p className='font-55 font-white'>
                    <i>Get It All Done In One Place</i>
                </p>
                <p className='spaced-out-text font-white font-18'>
                    Find the vehicle of your dreams, get estimates on any required work or find and book that service with our trusted network of repair shops.
                </p>
            </div> */}
            <Footer />
        </div>
    )
}

export default HomePage2;