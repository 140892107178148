import React, { useState, useContext, useEffect } from "react";
import { VehicleContext } from "../use context/vehicle-object.context";
import './radio-button.style.scss';

function RadioButtonTile({ label, value, name, property }) {
  const { vehicleObject, setVehicleObject } = useContext(VehicleContext);
  const [isChecked, setIsChecked] = useState(vehicleObject[property] === value);
  const [lightMode, setLightMode] = useState(false);

  useEffect(() => {
    setIsChecked(vehicleObject[property] === value);
  }, [vehicleObject, property, value]);

  const handleRadioChange = (event) => {
    const { value } = event.target;

    if (value === "Yes" || value === "No" || value === "1" || value === "2" || value === "3+") {
      setVehicleObject({
        ...vehicleObject,
        [property]: value,
      });
      setIsChecked(value);
    }
  };

  return (
    <label
        style={{
          display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width:'200px',
            height:'70px',
            // maxWidth: "160px",
            maxHeight: "120px",
            minWidth:'100px',
            // minHeight:'100px',
            borderRadius:'3px',
            margin: '15px auto',
            backgroundColor: lightMode ? isChecked ? 'rgb(235,235,235)' : 'rgba(255,255,255)' : isChecked ? 'rgba(255,255,255,0.2)' : 'rgba(44,72,81,0.3)',
            border: lightMode ? isChecked ? '1px solid rgba(136, 8, 8,.5)' : '1px solid rgb(5,5,5)' : isChecked ? '1px solid rgb(252,91,75)' : '1px solid white',
            cursor: 'pointer',
        }}
    >
      <h4 className="radio-btn">{label}</h4>
      <input
        type="radio"
        style={{ display: 'none' }}
        value={value}
        name={name}
        checked={isChecked}
        onChange={handleRadioChange}
      />
    </label>
  );
}

export default RadioButtonTile;
