import HeaderText from "../../header text/header-text.component";
import './shop-map.style.scss';

const BatteryInstructions = () => {

    return (
        <div style={{color:'white'}}>
            <div className="faq-header">
                <HeaderText text='How to Replace a Car Battery' />
            </div>
            <div className="diy-instructions-container">
                <h3>Here's what you'll need:</h3>
                <ul>
                    <li>Replacement car battery</li>
                    <li>Safety glasses</li>
                    <li>Gloves</li>
                    <li>Wrench or socket set</li>
                    <li>Battery terminal cleaner (optional)</li>
                    <li>Battery terminal grease (optional)</li>
                </ul>
                <h3>Now, let's proceed with the battery replacement process:</h3>
                <ol>
                    <li>Prepare for the battery replacement:
                        <ul>
                            <li>Park your vehicle in a well-ventilated area and engage the parking brake.</li>
                            <li>Put on safety glasses and gloves to protect yourself from any potential hazards.</li>
                        </ul>
                    </li>
                    <li>Locate the battery:
                        <ul>
                            <li>Open the hood of your car and locate the battery. It is typically found in the engine bay, often on one side.</li>
                            <img src={require("../../images/battery.jpg")} alt="battery" />
                        </ul>
                    </li>
                    <li>Identify battery terminals:
                        <ul>
                            <li>Take note of the battery terminals. The positive terminal is usually marked with a "+" sign, and the negative terminal with a "-" sign. It's important to identify them correctly.</li>
                        </ul>
                    </li>
                    <li>Disconnect the negative terminal:
                        <ul>
                            <li>Using a wrench or socket, loosen the nut or bolt on the negative terminal (usually black or marked with a "-" sign).</li>
                            <li>Once loose, carefully remove the negative terminal from the battery post.</li>
                            <li>To avoid accidental contact with the terminal, you can cover it with a cloth or tape.</li>
                            <img src={require("../../images/batteryNegative.jpg")} alt="battery" />
                        </ul>
                    </li>
                    <li>Disconnect the positive terminal:
                        <ul>
                            <li>Repeat the same process for the positive terminal (usually red or marked with a "+" sign).</li>
                            <li>Loosen the nut or bolt and remove the positive terminal from the battery post.</li>
                            <li>Ensure that the terminals are kept away from each other to prevent accidental contact.</li>
                        </ul>
                    </li>
                    <li>Remove the old battery:
                        <ul>
                            <li>Locate the battery hold-down clamp or bracket that secures the battery in place.</li>
                            <li>Remove any screws, nuts, or brackets holding the battery in position.</li>
                            <li>Carefully lift the old battery out of the battery tray and place it aside.</li>
                        </ul>
                    </li>
                    <li>Clean the battery terminals (optional):
                        <ul>
                            <li>If the terminals are corroded or dirty, you can clean them using a battery terminal cleaner and a wire brush.</li>
                            <li>Apply the cleaner to the terminals and scrub them gently with the wire brush until the corrosion is removed.</li>
                            <li>Wipe the terminals with a clean cloth to remove any residue.</li>
                        </ul>
                    </li>
                    <li>Install the new battery:
                        <ul>
                            <li>Place the new battery in the battery tray.</li>
                            <li>Reinstall the battery hold-down clamp or bracket.</li>
                        </ul>
                    </li>
                    <li>Reconnect the positive terminal:
                        <ul>
                            <li>Apply a small amount of battery terminal grease to the positive terminal.</li>
                            <li>Place the positive terminal on the battery post and tighten the nut or bolt.</li>
                        </ul>
                    </li>
                    <li>Reconnect the negative terminal:
                        <ul>
                            <li>Apply a small amount of battery terminal grease to the negative terminal.</li>
                            <li>Place the negative terminal on the battery post and tighten the nut or bolt.</li>
                        </ul>
                    </li>
                    <li>Test the new battery:
                        <ul>
                            <li>Start the engine and check that all electrical components are working properly.</li>
                        </ul>
                    </li>
                </ol>
                <h3>And that's it! You've successfully changed your own battery.</h3>
            </div>
        </div>
    )
}

export default BatteryInstructions;