import { useState, useContext } from "react";
import { VehicleContext } from "../../../use context/vehicle-object.context";
import NavBar from "../../../navbar/navbar.componet";
import HeaderText from "../../../header text/header-text.component";
import Shapes from "../../../shapes/shapes.component";
import Footer from "../../../footer/footer.component";
import companyName from "../../../../data/companyName";
import '../../../../index.scss'
import '../../index/index.style.scss'

const OurStory = () => {

  const { mobileView } = useContext(VehicleContext)

        return (
            <div>
                <NavBar mobileScreen={mobileView} />
                <div className="contact-us-container">
                    <div className="contact-us-info">
                        <Shapes className="shapes-3" />
                            <div className="contact-us-form">
                            <HeaderText text="Our Story" />
                            <h1>How it all began.</h1>
                            <p className="spaced-out-text">
                                Remeber horse drawn buggies? Neither.<br/>
                                Remeber your first car? You bet we do. <br/>
                                We are a team of vehicle enthusiasts, product experts, web developers and vehicle auctioneers.
                                With decades of experience in the automotive industry we have felt, smelt and experienced it all. 
                                Over the course of several years we compiled a unique database consisting of millions of points of data
                                allowing us to leverage a few short questions, look deep into your soul and match you with the most 
                                appropriate vehicle.
                                <br/><br/>                                
                                But choosing the right vehicle is only half the battle. What happens next? What happens during
                                the length of ownership? Repairs, break downs and stress! <br/><br/>
                                We've been in the industry since adolescence and we understand the joy and pain of vehicle 
                                ownership. At {companyName} we want to simplify your decision process and save you money, time and suffering!
                                We have worked hand in hand with thousands of vehicle dealerships, repair shops, body shops and backyard mechanics.
                                We understand that at times it feels like hand to hand combat. Tough negotiations, raising labour costs, expensive parts..
                                Have you ever asked yourself "Is this price right?" or "Can it really cost this much?".
                                We aim to bring transparency to the marketplace and help you make the best decision possible 
                                while faced with a challenging situation. <br/>
                                We hope you enjoy our tools and can walk away from a purchase or repair with a little more information 
                                and a little more confidence.

                            </p>
                            </div>  
                    </div> 
                </div>
                <Footer />
            </div>
        );
        }

export default OurStory;
