import { useState, useContext } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import Slider from "../../input/input";

import './vehicle-fuel-efficiency.style.scss'

const VehicleFuelEfficiency = () => {

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    const updateValue = (newValue) => {
        setVehicleObject({...vehicleObject, fuelEfficiency: newValue})
    }

    return (
        <div className="slider">
            <h2>Select Preferred Fuel Efficiency</h2>
            <Slider className={'slider-parent'} onValueChange={updateValue} value={vehicleObject.fuelEfficiency} lowMsg="less efficient" highMsg="more efficient" />
        </div>
    );
  };
  
  export default VehicleFuelEfficiency;

  