import RadioButtonTile from '../../radio button/radio-button.component';
import { childrenArray } from '../../../data/vehicleOptions';
import ValidationMessage from '../../validation/validation.component';

const VehicleChildren = () => {
 
    return (
        <>
        <h2>* Do You Have Children?</h2>
        <ValidationMessage message={'Please select an option'} />
        {childrenArray.map((type) => {
            return(
            <RadioButtonTile key={type} label={type} value={type} name="children" property="children" />
            )
        })}

        </>
    )
}

export default VehicleChildren;