import React, { useState, useContext, useEffect } from "react";
import { VehicleContext } from "../use context/vehicle-object.context";

import './checkbox.style.scss'

function CheckboxTileSimple({ label, value }) {

  const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

  const [isChecked, setIsChecked] = useState(() => {
    if(      
      label === "Front Wheel Drive" ||
      label === "Rear Wheel Drive" ||
      label === "Four Wheel Drive" ||
      label === "All Wheel Drive"
    ) {
    return vehicleObject.drivetrain.includes(value)
    }
    else if(
      label === "Car" ||
      label === "SUV" ||
      label === "Van" ||
      label === "Pickup Truck"
    ) {
    return vehicleObject.category.includes(value)
    }
    else if(
      label === "Sedan" ||
      label === "Hatch" ||
      label === "Wagon" ||
      label === "Convertible" ||
      label === "Pickup"
    ) {
    return vehicleObject.subCategory.includes(value)
    }
    else if(
      label === "Gas" ||
      label === "Diesel" ||
      label === "Hybrid" ||
      label === "Electric"
    ) {
    return vehicleObject.fuel.includes(value)
    }
    else if(
      label === "Automatic" ||
      label === "Manual"
    ) {
    return vehicleObject.transmission.includes(value)
    }
    else if(
      label === "2" ||
      label === "5" ||
      label === "7" ||
      label === "8"
    ) {
    return vehicleObject.seating.includes(value)
    }
    else if(
      label === "No" ||
      label === "1" ||
      label === "2" ||
      label === "3" ||
      label === "4+"
    ) {
    return vehicleObject.children.includes(value)
    }
    else if(
      label === "Golf Clubs" ||
      label === "Dogs" ||
      label === "Cargo"
    ) {
    return vehicleObject.cargoRating.includes(value)
    }
    else if(
      label === "Snow" ||
      label === "Offroad" ||
      label === "Highway" ||
      label === "City" ||
      label === "Unpaved" ||
      label === "Mountains"
    ) {
    return vehicleObject.conditions.includes(value)
    }
    else if(
      label === "Skiing" ||
      label === "Hiking" ||
      label === "Camping" ||
      label === "Biking" ||
      label === "Road Trips" ||
      label === "Organized Sports"
    ) {
    return vehicleObject.activities.includes(value)
    }
    else if(
      label === "USA" ||
      label === "Asia" ||
      label === "Europe" 
    ) {
    return vehicleObject.originCountry.includes(value)
    }
    else if(
      label === "Yes" ||
      label === "No"
    ) {
    return vehicleObject.towing.includes(value)
    } else {
      return ""
    }
});

const handleCheckboxChange = (event) => {
  const { checked } = event.target;
  if(
    label === "Front Wheel Drive" ||
    label === "Rear Wheel Drive" ||
    label === "Four Wheel Drive" ||
    label === "All Wheel Drive"
    ) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      drivetrain: [...vehicleObject.drivetrain, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      drivetrain: vehicleObject.drivetrain.filter(
        (drivetrain) => drivetrain !== value
      ),
    });
  }
} else if (
  label === "Car" ||
  label === "SUV" ||
  label === "Van" ||
  label === "Pickup Truck"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      category: [...vehicleObject.category, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      category: vehicleObject.category.filter(
        (category) => category !== value
      ),
    });
  }
} else if (
  label === "Sedan" ||
  label === "Hatch" ||
  label === "Wagon" ||
  label === "Convertible" ||
  label === "Pickup"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      subCategory: [...vehicleObject.subCategory, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      subCategory: vehicleObject.subCategory.filter(
        (subCategory) => subCategory !== value
      ),
    });
  }
} else if (
  label === "Gas" ||
  label === "Diesel" ||
  label === "Hybrid" ||
  label === "Electric"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      fuel: [...vehicleObject.fuel, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      fuel: vehicleObject.fuel.filter(
        (fuel) => fuel !== value
      ),
    });
  }
} else if (
  label === "Automatic" ||
  label === "Manual"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      transmission: [...vehicleObject.transmission, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      transmission: vehicleObject.transmission.filter(
        (transmission) => transmission !== value
      ),
    });
  }
}  else if (
  label === "2" ||
  label === "5" ||
  label === "7" ||
  label === "8"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      seating: [...vehicleObject.seating, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      seating: vehicleObject.seating.filter(
        (seating) => seating !== value
      ),
    });
  }
}  else if (
  label === "No" ||
  label === "1" ||
  label === "2" ||
  label === "3" ||
  label === "4+"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      children: [...vehicleObject.children, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      children: vehicleObject.children.filter(
        (children) => children !== value
      ),
    });
  }
}  else if (
  label === "Golf Clubs" ||
  label === "Dogs" ||
  label === "Cargo"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      cargoRating: [...vehicleObject.cargoRating, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      cargoRating: vehicleObject.cargoRating.filter(
        (cargoRating) => cargoRating !== value
      ),
    });
  }
}  else if (
  label === "Snow" ||
  label === "Offroad" ||
  label === "Highway" ||
  label === "City" ||
  label === "Unpaved" ||
  label === "Mountains"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      conditions: [...vehicleObject.conditions, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      conditions: vehicleObject.conditions.filter(
        (conditions) => conditions !== value
      ),
    });
  }
}  else if (
  label === "Skiing" ||
  label === "Hiking" ||
  label === "Camping" ||
  label === "Biking" ||
  label === "Road Trips" ||
  label === "Organized Sports"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      activities: [...vehicleObject.activities, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      activities: vehicleObject.activities.filter(
        (activities) => activities !== value
      ),
    });
  }  
} else if (
  label === "USA" ||
  label === "Asia" ||
  label === "Europe" 
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      originCountry: [...vehicleObject.originCountry, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      originCountry: vehicleObject.originCountry.filter(
        (originCountry) => originCountry !== value
      ),
    });
  }  
} else if (
  label === "Yes" ||
  label === "No"
) {
  if (checked) {
    setVehicleObject({
      ...vehicleObject,
      towing: [...vehicleObject.towing, value],
    });
  } else {
    setVehicleObject({
      ...vehicleObject,
      towing: vehicleObject.towing.filter(
        (towing) => towing !== value
      ),
    });
  }
}
  setIsChecked(checked);
};

  return (
    <div className="checkbox-simple-container">
        <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange} 
        />  
        <p className="checkbox-btn-label">{label}</p>
    </div>
  );
}

export default CheckboxTileSimple;