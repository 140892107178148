import React, { useState, useRef, useEffect, useContext } from 'react';
import { GoogleMap, Marker, InfoWindow, LoadScript, StandaloneSearchBox, Circle } from '@react-google-maps/api';
import './shop-map.style.scss';
import axios from 'axios';
import NavBar from '../../navbar/navbar.componet';
import Footer from '../../footer/footer.component';
import Loader from '../../loader/loader-component';
import OilInstructions from './oilChange.component';
import BatteryInstructions from './batteryChange.component';
import BrakePadChangeInstructions from './brakePadChange.component';
import TireChangeInstructions from './tireChange.component';
import EngineTuneUp from './tuneUp.component';
import { VehicleContext } from '../../use context/vehicle-object.context';

const ShowMap = () => {

  const { mobileView } = useContext(VehicleContext)

  const [searchResults, setSearchResults] = useState([]);
  const [locationSearchResults, setLocationSearchResults] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const searchBoxRef = useRef(null);
  const searchBoxRefLocation = useRef(null);
  const inputRef = useRef(null);
  const mapRef = useRef(null); // Reference to Google Map component
  const apiKey = 'AIzaSyAt-h2vlyovHU9U7RcLOJu6fvoh026P2jg';
  const [searchText, setSearchText] = useState('');
  const [userLocation, setUserLocation] = useState(null);
  const [enterUserAddress, setEnterUserAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toolTipSearch, setToolTipSearch] = useState('');
  const [doItYourself, setDoItYourself] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);

  const oilChangeString = 'do your own oil change.';
  const batteryChangeString = 'change your battery'; 
  const brakePadChangeString = 'replace your brake pads.';
  const tireChangeString = 'change a tire.';
  const tuneUpString = 'perform a tune up.';

//   useEffect(() => {
    
//     const fetchData = async () => {
//       const config = {
//         method: 'get',
//         url: 'https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJyf7Xy9NzhlQRyK08yehgKmc&fields=name%2Crating%2Cformatted_phone_number&key=AIzaSyAt-h2vlyovHU9U7RcLOJu6fvoh026P2jg',
//         headers: {
//             // 'Access-Control-Allow-Origin': 'http://localhost:3000/shopMap',
//             // 'Access-Control-Allow-Origin': '*',
//             // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
//             // 'Access-Control-Allow-Headers': 'Content-Type',
//           }
//               };

//       try {
//         const response = await axios(config);
//         console.log(JSON.stringify(response.data));
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     fetchData();
//   }, []);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleMarkerClose = () => {
    setSelectedMarker(null);
  };

  const handleSearch = (googleSearch, tooltipSearch) => {
    setSearchText(googleSearch);
    setToolTipSearch(tooltipSearch);
  };
    
  const handleComponentSelection= () => {
    if (toolTipSearch === oilChangeString) {
      setDoItYourself(true);
      setSelectedComponent(<OilInstructions />);
    } else if (toolTipSearch === batteryChangeString) {
      setDoItYourself(true);
      setSelectedComponent(<BatteryInstructions />);
    } else if (toolTipSearch === brakePadChangeString) {
      setDoItYourself(true);
      setSelectedComponent(<BrakePadChangeInstructions />);
    } else if (toolTipSearch === tireChangeString) {
        setDoItYourself(true);
        setSelectedComponent(<TireChangeInstructions />);
    } else if (toolTipSearch === tuneUpString) {
        setDoItYourself(true);
        setSelectedComponent(<EngineTuneUp />);
    } else {
      setSelectedComponent(null); // No selection made
    }
  };

  useEffect(() => {
    if (searchText !== '') {
      inputRef.current.focus();
      triggerEnterKeyPress();
    }
  }, [searchText]);

  useEffect(() => {
    // Fetch user's location when component mounts
    getUserLocation();
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          setEnterUserAddress(true);
          console.log('Error getting user location:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const triggerEnterKeyPress = () => {
    const enterKeyEvent = new KeyboardEvent('keydown', {
      key: 'Enter',
      keyCode: 13,
      which: 13,
      bubbles: true,
    });
    inputRef.current.dispatchEvent(enterKeyEvent);
  };

  const handlePlacesChanged = async () => {
    setLoading(true);
    console.log(`searchBoxRef.current: ${searchBoxRef.current}`)
    const places = await searchBoxRef.current.getPlaces();
    console.log('Places:', places);
    setSearchResults(places);
    setMarkers([]);
    if (places.length > 0) {
      const newMarkers = places.map((place) => ({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }));
      setLoading(false);
      setMarkers(newMarkers);
    }
  };

  const StarRating = ({ rating, numberOfRatings }) => {
    const fullStars = Math.floor(rating);
    const decimalPart = rating - fullStars;
    const starRange = [...Array(5).keys()]; // Create an array of 0 to 4 to represent the star range
  
    return (
      <div className="star-rating">
        {starRange.map((index) => (
          <img
            key={index}
            src={
            index < fullStars
                ? require("../../images/star.png")
                : index === fullStars && decimalPart < 0.4
                ? require("../../images/oneThirdStar.png")
                : index === fullStars && decimalPart > 0.6
                ? require("../../images/twoThirdStar.png")
                : require("../../images/halfStar.png")
            }
            alt={index < fullStars ? "Full Star" : index === fullStars && decimalPart > 0 ? "Half Star" : "Empty Star"}
            style={{ width: '16px'}}
          />
        ))}
        <span className='number-of-ratings'>({numberOfRatings})</span>
      </div>
    );
  };

  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={['places']}>
        <NavBar mobileScreen={mobileView} />     
        {doItYourself && <span className='diy-close-btn' onClick={() => setDoItYourself(false)}>X</span>}
        {doItYourself ? selectedComponent :
        <>
        {!enterUserAddress && (
        <div className="shop-map-search">
            <div className='hideThis'>
                <StandaloneSearchBox onLoad={(ref) => (searchBoxRef.current = ref)} onPlacesChanged={handlePlacesChanged}>
                <input
                    type="text"
                    placeholder="Search for places"
                    className="shop-map-search-input"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    ref={inputRef}
                />
                </StandaloneSearchBox>
            OR <br /><br />
            </div>
            <div className="shop-map-checkboxes">
                <p>Select a repair type:</p> <br /><br />
                <input type="radio" id="radio1" name="repair" onChange={() => handleSearch('oil change', oilChangeString)} />
                <label htmlFor="radio1">Oil Change</label><br />

                <input type="radio" id="radio2" name="repair" onChange={() => handleSearch('vehicle brake shop', brakePadChangeString)} />
                <label htmlFor="radio2">Brake Replacement</label><br />

                <input type="radio" id="radio3" name="repair" onChange={() => handleSearch('vehicle tire change', tireChangeString)} />
                <label htmlFor="radio3">Tire Shop</label><br />

                <input type="radio" id="radio4" name="repair" onChange={() => handleSearch('vehicle battery replacement', batteryChangeString)} />
                <label htmlFor="radio4">Battery Replacement</label><br />

                <input type="radio" id="radio5" name="repair" onChange={() => handleSearch('vehicle engine tune up', tuneUpString)} />
                <label htmlFor="radio5">Engine Tune-up</label><br />

                <input type="radio" id="radio6" name="repair" onChange={() => handleSearch('vehicle engine repair shop', null)} />
                <label htmlFor="radio6">Major Engine Work</label><br />

                <input type="radio" id="radio7" name="repair" onChange={() => handleSearch('vehicle transmission shop')} />
                <label htmlFor="radio7">Drivetrain Work</label><br />

                <input type="radio" id="radio8" name="repair" onChange={() => handleSearch('vehicle exhaust muffler shop')} />
                <label htmlFor="radio8">Exhaust Work</label><br />

                <input type="radio" id="radio9" name="repair" onChange={() => handleSearch('vehicle diagnosis')} />
                <label htmlFor="radio9">Diagnostics</label><br />

                <input type="radio" id="radio10" name="repair" onChange={() => handleSearch('vehicle parts store')} />
                <label htmlFor="radio10">Vehicle Parts</label><br />

                <input type="radio" id="radio11" name="repair" onChange={() => handleSearch('vehicle electric wiring shop')} />
                <label htmlFor="radio11">Electrical Work</label><br />

                {/* <input type="button" value="Search" onClick={handleSearch} /> */}        </div>
        </div>
        )}
        <div style={{ height: '100vh', width: '100%' }}>
        { loading && <Loader /> }
        {toolTipSearch && toolTipSearch !== ' '+null && 
        <div className='tooltip-container'>
            <p className='tooltip-header'>Fix it yourself?</p>
            <div className='tooltip-body'>
                <p>Click below for helpful tips on how to &nbsp;
                <a href='#' onClick={handleComponentSelection}>{toolTipSearch}</a></p>
            </div>    
        </div>
        }
        <GoogleMap
          ref={mapRef}
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={userLocation || { lat: 49.3, lng: -123.2194 }} // Use user's location if available, otherwise use default center
          zoom={10}
        >
        {enterUserAddress && 
        <div className='user-location-search'>
            <p>Please enter your location</p>
            <StandaloneSearchBox
                onLoad={(ref) => {
                    searchBoxRefLocation.current = ref;
                }}
                onPlacesChanged={async () => {
                const places = await searchBoxRefLocation.current.getPlaces();
                setLocationSearchResults(places);
                if (places.length > 0) {
                    setUserLocation({ lat: places[0].geometry.location.lat(), lng: places[0].geometry.location.lng() })
                    setEnterUserAddress(false);
                    // const newMarkers = places.map((place) => ({
                    //   lat: place.geometry.location.lat(),
                    //   lng: place.geometry.location.lng(),
                    // }));
                }
                }}
            >
                <input type="text" placeholder="Enter your location" />
            </StandaloneSearchBox>
        </div>
        }
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={marker}
              onClick={() => handleMarkerClick(marker)}
              label=""
            />
          ))}

          {selectedMarker && (
            <InfoWindow
              position={selectedMarker}
              onCloseClick={handleMarkerClose}
              disableAutoPan
              disablePanOnClick // Disable panning when the info window is opened
            >
            <div className='info-window-container'>
            <div className='info-window-business-name'>{searchResults[markers.indexOf(selectedMarker)].name}</div>
            <div className='info-window-business-address'>{searchResults[markers.indexOf(selectedMarker)].formatted_address}</div>
            <StarRating rating={searchResults[markers.indexOf(selectedMarker)].rating} numberOfRatings={searchResults[markers.indexOf(selectedMarker)].user_ratings_total} />
            </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
      </>}
      <Footer />
    </LoadScript>
  );
};

export default ShowMap;


