import { useState, useContext } from "react";
import { VehicleContext } from "../../../use context/vehicle-object.context";
import NavBar from "../../../navbar/navbar.componet";
import Collapsible from 'react-collapsible';
import HeaderText from "../../../header text/header-text.component";
import Footer from "../../../footer/footer.component";

const Faqs = () => {

    const { mobileView } = useContext(VehicleContext)

    return (

        <div>
            <NavBar mobileScreen={mobileView} />
            <div className="faq-header">
                <HeaderText text="Frequently Asked Questions" />
            </div>
            <div className="faqs-container">
                <Collapsible trigger="How does the algorithm work?">
                    <p>
                        Our algorithm is quite complex. At its core the model takes approximately 22 parameters which the user enters and 
                        creates a profile. Our algorithm runs your unique profile against our propietary database and looks for "best match".
                        Every potential vehicle match has several "Scores" defined by industry and user standards. As the model compares your
                        profile against each potential match a score is created and aggregated at the end. Each score has a unique weight which
                        remains dynamic and is influenced by your individual profile. After the model has run it presents you the most likely match
                        by ranking based on score, the best match always being 100% and the rest are relative to this score.
                    </p>
                </Collapsible>
                <Collapsible trigger="Can I select more than one option?">
                    <p>
                        Absolutely. There are a few questions which require a single answer, however most allow you to select multiple options if
                        you would like to widen your search results. For example you can search for both SUV and Car as well as both Automatic and Manual.
                    </p>
                </Collapsible>
                <Collapsible trigger="What vehicles are included in the database?">
                    <p>
                        Our database consists of over 55,000 North American market vehicles. These vehicles may be manufactured or assembled
                        anywhere in the world, however they were only targeted towards the North American market.

                        The database consists of all vehicle tyles - convertibles, sedans, supercars, pickup trucks, work vans etc.

                        Electric vehicles are included.

                        Motorcycles and recreational vehicles are not included.
                    </p>
                </Collapsible>
                <Collapsible trigger="Why do my results not match my expectations?">
                    <p>
                        As our model is building a unique profile (often so unique it is never duplicated), keeping this in mind is makes
                        assumptions based on your parameters and it's learned experience. 

                        It is important to make sure you treat this tool with an objective approach and try to be as specific as possible.                        
                    </p>
                </Collapsible>
                <Collapsible trigger="Is there any bias?">
                    <p>
                        Yes. While creating our database we had to make several decisions as there were many data sources to consider 
                        when scoring a vehicle's qualities. We did our best to leave our personal experience and judgements at the door 
                        however there were times where we had to make a group decision to weigh or score a particular trait or issue based
                        on our own learned experiences.
                    </p>
                </Collapsible>
            </div>
            <Footer />
        </div>

    );
    }

export default Faqs;
