import './header-text.styles.scss';

const HeaderText = ({ text }) => {
    return (
        <div className="header-text">
            <h1>{text}</h1>
        </div>
    )
}

export default HeaderText;