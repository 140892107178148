import { useState, useContext } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import Slider from "../../input/input";
import InputRange from 'react-input-range';

const VehicleYear = () => {
    
    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    const [dualRange, setDualRange] = useState({ min: vehicleObject.minYear, max: vehicleObject.maxYear });

    const updateYear = (newValue) => {
        setVehicleObject({
          ...vehicleObject,
          minYear: dualRange.min,
          maxYear: dualRange.max
        });
      };
      

    return (
        <div className="year-container">
            <h2>Select Year Range</h2>
            <div className="dual-slider">
                <InputRange
                    step={1}
                    minValue={2001}
                    maxValue={2023}
                    value={dualRange}
                    onChange={(value) => {
                        setDualRange(value);
                        updateYear(value);
                    }}
                    formatLabel={value => `${value}`}
                    draggableTrack
                />
                <div className="minMaxValue-container">
                    <div className="minMaxValue">
                        <div>Min</div>
                        {dualRange.min}
                    </div>
                    <div className="minMaxValue">
                        <div>Max</div>
                        {dualRange.max}
                    </div>
                </div>
            </div>
        </div>
    );
  };

export default VehicleYear;