import React from 'react';
import Loader from '../../../loader/loader-component';

const MoreInformation = ({ vehicle, mobileScreen, vehicleInfoPage, hideVehicleInfoPage, vehicleWriteUp, showLoader }) => {

    const vehicleTableData = (img, title, data, optional) => {

        return(
          <tr>
            <td>
              <img className='vehicle-data-img' src={require(`../../../images/${img}`)} />
            </td>
            <td>
              <b>
              {title}
              </b>
            </td>
            <td>
              {data}{optional}
            </td>
          </tr>              
        )
      }

    return (
        <div className='vehicle-info-container'>
          {vehicleInfoPage === vehicle.id &&
          <div className='vehicle-info-page'>
          <div>
            <h1 className='vehicle-make-model-header'>{vehicle.year} {vehicle.make} {vehicle.model}</h1>
            <h1 className='close-btn' onClick={hideVehicleInfoPage}><span>x</span></h1>
              <div className='vehicle-write-up-container'>
                {vehicleWriteUp}
                {showLoader && <Loader />}
              </div>
              <div className='vehicle-data-container'>
                <div>
                  <table className='vehicle-data-table1'>
                  {vehicleTableData('odoWhite.png', 'Average Mileage:', ((new Date().getFullYear() - vehicle.year)*15000).toLocaleString()," km")}
                  {vehicleTableData('dollar.png', 'Avg Price:', '$' + (Math.trunc(vehicle.msrp_cdn * .8)), " - $" + vehicle.msrp_cdn)}
                  {vehicleTableData('pistonWhite.png', 'Cylinders:', vehicle.cylinders)}
                  {vehicleTableData('engineWhite.png', 'Engine Size:', vehicle.engine_size, 'L')}
                  {vehicleTableData('hpWhite.png', 'Horsepower:', vehicle.horsepower, 'hp')}
                  {vehicleTableData('axleWhite.png', 'Drivetrain:', vehicle.drive_train)}
                  {vehicleTableData('transmissionWhite.png', 'Transmission:', vehicle.transmission_short)}
                  {vehicleTableData('fuelWhite.png', 'Fuel Type:', vehicle.fuel_short)}
                  {vehicleTableData('mpgWhite.png', 'Fuel Economy:', vehicle.combined_mpg, " mpg")}
                  {vehicleTableData('seatWhite.png', 'Seats:', vehicle.seats)}
                  </table>
                </div>
              </div>
          </div>
          <div className='vehicle-ratings-list'>
            {!mobileScreen &&
             <img className='vehicle-info-img' 
              src={vehicle.image_url} 
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=require('../../../images/image-placeholder.jpeg');
              }} />
             }
                  <div>This vehicle has above average ratings in:</div>
                    <ul>
                    {vehicle.luxury_score >= 7 && <li>Luxury</li>}
                    {vehicle.safety_score >= 7 && <li>Safety</li>}
                    {vehicle.maintenance_score >= 7 && <li>Maintenance</li>}
                    {vehicle.depreciation_score >= 7 && <li>Depreciation</li>}
                    {vehicle.brand_score >= 7 && <li>Brand</li>}
                    {/* actvitiy score is used for cargo as well */}
                    {vehicle.activity_score >= 7 && <li>Cargo</li>}
                    {vehicle.activity_score >= 7 && <li>Activity</li>}
                    {vehicle.activity_score >= 7 && <li>Conditions</li>}
                    {vehicle.sport_score >= 7 && <li>Performance</li>}
                    {vehicle.luxury_score < 7 && vehicle.safety_score < 7 && vehicle.maintenance_score < 7 && vehicle.depreciation_score < 7 && vehicle.brand_score < 7 && vehicle.activity_score < 7 && vehicle.sport_score < 7 ? <li>No Categories</li> : null}
                    </ul>
                  <div>This vehicle has below average ratings in:</div>
                    <ul>
                    {vehicle.luxury_score < 6 && <li>Luxury</li>}
                    {vehicle.safety_score < 6 && <li>Safety</li>}
                    {vehicle.maintenance_score < 6 && <li>Maintenance</li>}
                    {vehicle.depreciation_score < 6 && <li>Depreciation</li>}
                    {vehicle.brand_score < 6 && <li>Brand</li>}
                    {/* actvitiy score is used for cargo as well */}
                    {vehicle.activity_score < 6 && <li>Cargo</li>}
                    {vehicle.activity_score < 6 && <li>Activity</li>}
                    {vehicle.activity_score < 6 && <li>Conditions</li>}
                    {vehicle.sport_score < 6 && <li>Performance</li>}
                    </ul>
                </div>
              </div>}
        </div>)

    };

    export default MoreInformation;
