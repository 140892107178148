

const NoResults = ({mobileScreen, setShowSideBarTrue, mobileChangeMySearch}) => {

    return (
    <div className='no-match-msg'>
        <h1>It looks like no vehicles match your search.</h1>
        <h3> Try expanding your criteria.</h3>
        {mobileScreen && 
        <button 
        className="no-results-update" 
        onClick={() => (
            mobileChangeMySearch(),
            setShowSideBarTrue()
        )}>Change My Search</button>}
    </div>
    )
}

export default NoResults