import React, { useState, useContext } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import { cargoArray } from "../../../data/vehicleOptions";
import ValidationMessage from "../../validation/validation.component";

import CheckboxTile from '../../checkbox/checkbox.component';

const VehicleCargo = () => {

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    return (
        <>
            <h2>Select Any Cargo You Will Carry</h2>
            <ValidationMessage message={''} />
            {cargoArray.map((type) => {
                return(
                <CheckboxTile key={type} label={type} value={type} />
                )
            })}
        </>
    )
}

export default VehicleCargo;
