import { useState, useContext } from "react";
import { VehicleContext } from "../../../use context/vehicle-object.context";
import NavBar from "../../../navbar/navbar.componet";
import Footer from "../../../footer/footer.component";
import HeaderText from "../../../header text/header-text.component";
import companyName from "../../../../data/companyName";
import companyUrl from "../../../../data/companyName";

const TermsOfUse = () => {

  const { mobileView } = useContext(VehicleContext)
    
        return (
    
            <div>
                <NavBar mobileScreen={mobileView} />
                <div className="terms-of-use-container">
                    <div className="faq-header">
                        <HeaderText text="Terms of Use" />
                    </div>
                    <div className="terms-of-use-info">
                        <p className="spaced-out-text">                            
                        Welcome to {companyUrl}! 
                        <br/>                    
                        <br/>                    
                        These Terms of Use ("Agreement") govern your use of our website. By accessing or using our website, you agree to be bound by this Agreement. If you do not agree with any part of this Agreement, please refrain from using our website.
                        <br/>
                        <br/>
                        Acceptance of Terms
                        By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by this Agreement, along with any other policies or guidelines posted on the website.
                        <br/>
                        <br/>
                        User Eligibility
                        You must be 18 years old or older and have the legal capacity to enter into this Agreement. By using our website, you represent and warrant that you meet these eligibility requirements.
                        <br/>
                        <br/>
                        User Accounts
                        Some features of our website may require you to create an account. When creating an account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized access to or use of your account.
                        <br/>
                        <br/>
                        Intellectual Property
                        All content and materials available on our website, including but not limited to text, graphics, logos, images, audio clips, and software, are the property of [Your Company] or its licensors and are protected by intellectual property laws. You may not use, copy, reproduce, distribute, modify, or create derivative works of any content or materials without prior written consent from [Your Company].
                        <br/>
                        <br/>
                        User Conduct
                        When using our website, you agree to:
                        <br/>
                        <br/>
                        Comply with all applicable laws and regulations.
                        Respect the rights of others and refrain from engaging in any unlawful, harmful, or offensive behavior.
                        Not upload, post, or transmit any content that is infringing, defamatory, obscene, or otherwise objectionable.
                        Not disrupt or interfere with the security or functioning of our website or any related servers or networks.
                        Not attempt to gain unauthorized access to any part of our website or other users' accounts.
                        Limitation of Liability
                        To the maximum extent permitted by law, {companyName} and its affiliates, directors, employees, and agents shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your use of our website.
                        <br/>
                        <br/>
                        Modifications
                        We reserve the right to modify or update this Agreement at any time. We will notify you of any material changes by posting a notice on our website or by other means of communication. Your continued use of our website after such modifications shall constitute your acceptance of the updated Agreement.
                        <br/>
                        <br/>
                        Governing Law and Jurisdiction
                        This Agreement shall be governed by and construed in accordance with the laws of Canada and the USA. Any legal action or proceeding arising out of or in connection with this Agreement shall be exclusively brought in the courts of Canada and the USA.                            
                        </p>
                    </div> 
                </div>
                <Footer />
            </div>

        );
        }   

export default TermsOfUse;
