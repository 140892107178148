import React from 'react';
import HeaderText from "../../header text/header-text.component";
import './shop-map.style.scss';

const TireChangeInstructions = () => {

  return (
    <div style={{color:'white'}}>
        <div className="faq-header">
            <HeaderText text='How to Change a Tire' />
        </div>
        <div className="diy-instructions-container">

            
            <h3>Here's what you'll need:</h3>
            <ul>
                <li>Jack and lug wrench</li>
                <li>Spare tire</li>
                <li>Owner's manual</li>
            </ul>
            <h3>Now, let's proceed with the tire changing process:</h3>
            <ol>
                <li>Find a safe location:
                    <ul>
                        <li>Park your vehicle on a flat, level surface away from traffic.</li>
                        <li>Engage the parking brake for added safety.</li>
                    </ul>
                </li>
                <li>Prepare the tools:
                    <ul>
                        <li>Locate the spare tire, jack, and lug wrench in your vehicle.</li>
                        <li>Ensure the lug wrench fits the lug nuts on your tires.</li>
                        <li>If necessary, remove any hubcaps or wheel covers from the tire you're going to change.</li>
                    </ul>
                </li>
                <li>Loosen the lug nuts:
                    <ul>
                        <li>Use the lug wrench to turn each lug nut counterclockwise (lefty-loosey) about one full turn.</li>
                        <li>Don't remove the lug nuts completely at this stage; just loosen them.</li>
                    </ul>
                </li>
                <li>Position the jack:
                    <ul>
                        <li>Consult your vehicle's owner's manual for the proper jacking points.</li>
                        <li>Typically, these points are near the tires and are reinforced for jack placement.</li>
                        <li>Place the jack under the vehicle and raise it until the tire is about six inches off the ground.</li>
                        <img src={require('../../images/scissorJack.jpg')} alt="car jack" />
                    </ul>
                </li>
                <li>Remove the lug nuts and old tire:
                    <ul>
                        <li>Unscrew the loosened lug nuts completely and set them aside.</li>
                        <li>Remove the tire from the vehicle.</li>
                        <img src={require('../../images/lugNuts.jpg')} alt="remove lugs" />
                    </ul>
                </li>
                <li>Mount the spare tire:
                    <ul>
                        <li>Place the spare tire on the hub.</li>
                        <li>Align the rim of the spare tire with the wheel bolts, then put on the lug nuts.</li>
                        <li>Tighten the lug nuts by hand until they are all snug.</li>
                        <img src={require('../../images/spareTire.jpg')} alt="spare tire" />
                    </ul>
                </li>
                <li>Lower the vehicle:
                    <ul>
                        <li>Use the jack to lower the vehicle until the spare tire is resting on the ground.</li>
                        <li>Remove the jack from underneath the vehicle.</li>
                    </ul>
                </li>
                <li>Tighten the lug nuts:
                    <ul>
                        <li>Use the lug wrench to tighten the lug nuts in a star or crisscross pattern.</li>
                        <li>Alternate between lug nuts, tightening them gradually and evenly.</li>
                        <li>Ensure the lug nuts are tightened securely, but avoid over-tightening.</li>
                    </ul>
                </li>
                <li>Lower the jack: 
                    <ul>
                        <li>Use the jack to lower it completely, allowing the vehicle to rest on the spare tire.</li>
                        <li>Remove the jack from underneath the vehicle.</li>
                    </ul>
                </li>
                <li>Double-check lug nut tightness:
                    <ul>
                        <li>Using the lug wrench, go around each lug nut one more time to ensure they are tightened properly.</li>
                    </ul>
                </li>
            </ol>
        </div>
    </div>
    );
};

export default TireChangeInstructions;