import HeaderText from "../../header text/header-text.component";
import './shop-map.style.scss';

const OilInstructions = () => {

    return (
        <div style={{color:'white'}}>
            <div className="faq-header">
                <HeaderText text='How to Perform an Oil Change' />
            </div>
            <div className="diy-instructions-container">
                <h3 >Here's what you'll need:</h3>
                <ul>
                    <li>New oil filter</li>
                    <li>New engine oil (check your owner's manual for the recommended oil type and capacity)</li>
                    <li>Oil drain pan</li>
                    <li>Socket set or wrenches</li>
                    <li>Oil filter wrench</li>
                    <li>Funnel</li>
                    <li>Jack and jack stands (or ramps)</li>
                    <li>Shop towels or rags</li>
                    <li>Disposable gloves</li>
                </ul>

                <h3>Now, let's proceed with the oil change process:</h3>

                <ol>
                    <li>Prepare your vehicle:
                        <ul>
                            <li>Park your vehicle on a flat surface and engage the parking brake.</li>
                            <li>Allow the engine to cool down to prevent burns.</li>
                        </ul>
                    </li>
                    <li>Lift the vehicle:
                        <ul>
                            <li>If necessary, use a jack to lift the front of the vehicle. Place jack stands under the designated lift points for added safety. Alternatively, you can use ramps if you have them.</li>
                            <img src={require("../../images/carJack.jpg")} alt="vehicle lift" />
                        </ul>
                    </li>
                    <li>Locate the oil drain plug:
                        <ul>
                            <li>Crawl under the vehicle and locate the oil drain plug. It is typically located at the bottom of the engine oil pan. If you're having trouble finding it, consult your owner's manual for assistance.</li>
                            <img src={require("../../images/oilPlug.jpg")} alt="oil drain plug" />
                        </ul>
                    </li>
                    <li>Drain the oil:
                        <ul>
                            <li>Position the oil drain pan directly under the drain plug.</li>
                            <li>Using a socket or wrench that fits the drain plug, carefully loosen it by turning it counterclockwise.</li>
                            <li>Slowly remove the plug by hand and allow the old oil to drain completely into the pan.</li>
                            <li>Once the oil has drained, wipe off any excess oil from the drain plug and the surrounding area.</li>
                            <img src={require("../../images/drainOil.jpg")} alt="oil drain" />
                        </ul>
                    </li>
                    <li>Replace the oil filter:
                        <ul>
                            <li>Locate the oil filter, which can be found near the drain plug or on the side of the engine.</li>
                            <li>Use an oil filter wrench to remove the old oil filter. Be prepared for some oil spillage, so position the drain pan accordingly.</li>
                            <li>Before installing the new filter, lightly coat the rubber gasket on the top of the filter with fresh oil.</li>
                            <li>Screw the new oil filter onto the engine by hand until it's snug. Do not overtighten.</li>
                            <img src={require("../../images/oilFilter.jpg")} alt="oil filter" />
                        </ul>
                    </li>
                    <li>Refill with new oil:
                        <ul>
                            <li>Refer to your owner's manual to determine the correct oil type and capacity for your vehicle.</li>
                            <li>Using a funnel, pour the new oil into the engine oil filler opening. Pour slowly and check the dipstick periodically to avoid overfilling.</li>
                            <li>Once the proper oil level is reached, replace the oil filler cap.</li>
                            <img src={require("../../images/refilloil.jpg")} alt="oil filler" />
                        </ul>
                    </li>
                    <li>Lower the vehicle:
                        <ul>
                            <li>Carefully lower the vehicle from the jack stands or ramps.</li>
                        </ul>
                    </li>
                    <li>Check the oil level:
                        <ul>
                            <li>Start the engine and let it run for a few minutes to circulate the new oil.</li>
                            <li>Turn off the engine and wait for a few minutes to allow the oil to settle.</li>
                            <li>Check the oil level using the dipstick. Add more oil if necessary.</li>
                        </ul>
                    </li>
                    <li>Dispose of the old oil and filter:
                        <ul>
                            <li>Store the old oil in the containers the new oil came in and place the containers in a plastic bag.</li>
                            <li>Take the old oil and filter to a recycling center or auto parts store that accepts used oil and filters.</li>
                        </ul>
                    </li>
                </ol>
                <h3>And that's it! You've successfully changed your own oil.</h3>
            </div>
        </div>
    );
    }

    export default OilInstructions;