import { useState, useContext } from "react";
import { VehicleContext } from "../../../use context/vehicle-object.context";
import NavBar from "../../../navbar/navbar.componet";
import Footer from "../../../footer/footer.component";
import HeaderText from "../../../header text/header-text.component";
import companyUrl from "../../../../data/companyName";

const PrivacyPolicy = () => {

  const { mobileView } = useContext(VehicleContext)
    
        return (
    
            <div>
                <NavBar mobileScreen={mobileView} />
                <div className="terms-of-use-container">
                    <div className="faq-header">
                        <HeaderText text="Privacy Policy" />
                    </div>
                    <div className="terms-of-use-info">
                        <p className="spaced-out-text">
                        <b>Privacy Policy</b>
                        <br/>
                        <br/>
                        Thank you for visiting {companyUrl}. This Privacy Policy ("Policy") explains how we collect, use, and protect the personal information you provide to us when using our website and the vehicle-finding tool ("Tool"). By accessing or using our website and Tool, you consent to the collection and processing of your personal information as described in this Policy.
                        <br/>
                        <br/>
                        <b>Information We Collect</b>
                        <br/>
                        a. Personal Information:
                        <br/>
                        When you use our Tool, we may collect personal information you voluntarily provide, such as your name, contact details, location, vehicle preferences, and any other information you choose to provide.
                        <br/>
                        b. Automatically Collected Information: We may also automatically collect certain information when you use our website or Tool, including your IP address, browser type, device information, and usage data.
                        <br/>
                        <br/>
                        <b>Use of Information</b>
                        <br/>
                        a. Provide Services: We use the information collected to operate, maintain, and improve our website and Tool, and to provide you with the services and features you request.
                        <br/>
                        b. Personalization: We may use your information to personalize and enhance your experience on our website, such as by suggesting vehicles that match your preferences.
                        <br/>
                        c. Communication: We may use your contact information to respond to your inquiries, provide customer support, and send you relevant updates and notifications.
                        <br/>
                        d. Analytics and Research: We may use aggregated and anonymized data for analytics, research, and statistical purposes to improve our services and understand user preferences.
                        <br/>
                        <br/>
                        <b>Data Sharing</b>
                        <br/>
                        a. Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website and providing the Tool. These service providers are contractually obligated to handle your information securely and use it solely for the purposes specified by us.
                        <br/>
                        b. Legal Requirements: We may disclose your information if required by law or if we believe that such disclosure is necessary to comply with a legal obligation, protect our rights, or defend against legal claims.
                        <br/>
                        c. Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.
                        <br/>
                        <br/>
                        <b>Data Security</b>
                        <br/>
                        We implement industry-standard security measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                        <br/>
                        <br/>
                        <b>Third-Party Links</b>
                        <br/>
                        Our website may contain links to third-party websites or services. This Privacy Policy does not apply to those websites or services. We recommend reviewing the privacy policies of any third-party websites or services you visit.
                        <br/>
                        <br/>
                        <b>Children's Privacy</b>
                        <br/>
                        Our website and Tool are not intended for individuals under the age of [minimum age]. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected personal information from a child, please contact us, and we will promptly delete the information.
                        <br/>
                        <br/>
                        <b>Your Rights</b>
                        <br/>
                        You may have certain rights regarding your personal information, such as the right to access, update, correct, or delete your information. If you wish to exercise any of these rights, please contact us using the contact information provided below.
                        <br/>
                        <br/>
                        <b>Changes to this Privacy Policy</b>
                        <br/>
                        We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Policy on our website. Your continued use of our website or Tool after the changes indicates your acceptance of the updated Policy.
                        <br/>
                        <br/>
                        <b>Contact Us</b>
                        <br/>
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at vehicleselector@gmail.com.
                        </p>
                    </div> 
                </div>
                <Footer />
            </div>

   
        );
        }

export default PrivacyPolicy;
