

const Shapes = ({ className, h1Text, pText }) => {
    return (
        <section className={className}>
            <h1>{h1Text}</h1>
            <p className="spaced-out-text spaced-out-p">
            {pText}
            </p>
            <div class="curve"></div>
        </section>
    )
}

export default Shapes;

