import { useEffect, useState, useContext } from "react";
import { VehicleContext } from "../../../use context/vehicle-object.context";
import NavBar from "../../../navbar/navbar.componet";
import Shapes from "../../../shapes/shapes.component";
import Footer from "../../../footer/footer.component";
import HeaderText from "../../../header text/header-text.component";

const UpcomingProjects = () => {
    
      const { mobileView } = useContext(VehicleContext)

        return (
    
            <div>
                <NavBar mobileScreen={mobileView} />
                <div className="contact-us-container">
                    <div className="contact-us-info">
                        <div className="contact-us-form">
                        <HeaderText text="Upcoming Projects" />
                        <h1>What are we working on?</h1>
                            <h3>Cost to Repair Calculator</h3>
                            <p>Get ready for our "cost to repair" calculator which is in its final stages of testing. We a proven third party
                            API specializing in vehicle maintenance and repairs, we are excited to be offering a unique and localized calculator
                            to help you make an informed decision on when, where and how much to spend on an upcoming repair.
                            </p>
                            <h3>Fix or Sell</h3>
                            <p>
                            Leveraging our unique database as well as the "Cost to Repair" calculator we will help guide you through the decision
                            process of making an expensive repair or selling your vehicle as-is. We will compaire your market value with and without
                            the repairs being completed and provide you with a recommendation on what to do.
                            </p>
                        </div>  
                        <Shapes className="shapes-1" />
                    </div> 
                </div>
                <Footer />
            </div>
    
        );
        }

export default UpcomingProjects;