import React, { useState } from "react";

import './input.componet.style.scss'

export default function SliderElectric(props) {

const handleChange = (event) => {
    props.onValueChange(event.target.value)
}
    
  const [value, onChange]=useState(1);

  return (
    <div className="slider-parent">
      <div className="buble"> 
      {props.value}km
      </div>
      <span className="slider-message">{props.lowMsg}</span>
      <input 
        type="range" 
        step="10"
        min="50" 
        max="500" 
        value={props.value}
        onChange={handleChange}
      />
        <span className="slider-message">{props.highMsg}</span>
    </div>
  );
}