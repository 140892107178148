import CheckboxTile from "../../checkbox/checkbox.component";
import { seatingArray } from "../../../data/vehicleOptions";
import ValidationMessage from "../../validation/validation.component";

const VehicleSeating = () => {

  return (
    <>
      <h2>* Select Preferred Seating Capacity</h2>
      <ValidationMessage message={'Please select atleast one seating capacity'} />
      {seatingArray.map((type) => {
          return (
          <CheckboxTile key={type} label={type} value={type} />
          )
      })}
    </>
  );
}

export default VehicleSeating;