import React, { useState } from "react";

import './input.componet.style.scss'

export default function Slider(props) {

const handleChange = (event) => {
    props.onValueChange(event.target.value)
}
    
  const [value, onChange]=useState(1);

  return (
    <div className={props.className}>
      <div className="buble"> 
      {props.value}
      </div>
      <span className="slider-message">{props.lowMsg}</span>
        <input 
          type="range" 
          min="0" 
          max="10" 
          value={props.value}
          onChange={handleChange}
          className={props.className}
        />
        <span className="slider-message">{props.highMsg}</span>
    </div>
  );
}