import React from 'react';
import HeaderText from "../../header text/header-text.component";
import './shop-map.style.scss';

const BrakePadChangeInstructions = () => {

  return (
    <div style={{color:'white'}}>
        <div className="faq-header">
            <HeaderText text='How to Change Your Brake Pads' />
        </div>
        <div className="diy-instructions-container">
            <h3>Here's what you'll need:</h3>
            <ul>
                <li>New brake pads (specific to your vehicle's make and model)</li>
                <li>Jack and jack stands (or ramps)</li>
                <li>Lug wrench or socket set</li>
                <li>C-clamp or brake piston tool</li>
                <li>Brake cleaner spray</li>
                <li>Brake grease</li>
                <li>Gloves</li>
                <li>Safety glasses</li>
            </ul>
            <h3>Now, let's proceed with the brake pad replacement process:</h3>
            <ol>
                <li>Prepare for the brake pad replacement:
                    <ul>
                        <li>Park your vehicle on a flat surface and engage the parking brake.</li>
                        <li>Put on safety glasses and gloves to protect yourself during the process.</li>
                    </ul>
                </li>
                <li>Lift the vehicle:
                    <ul>
                        <li>Use a jack to lift the front or rear of the vehicle (whichever brake pads you're replacing).</li>
                        <li>Place jack stands under the vehicle's designated lift points for added safety. Alternatively, you can use ramps if you have them.</li>
                    </ul>
                </li>
                <li>Remove the wheel:
                    <ul>
                        <li>Using a lug wrench or socket set, loosen the lug nuts on the wheel you're working on.</li>
                        <li>Once loosened, lift the vehicle further or use a hydraulic jack stand to fully support the vehicle.</li>
                        <li>Completely remove the lug nuts and take off the wheel to expose the brake assembly.</li>
                    </ul>
                </li>   
                <li>Remove the brake caliper:
                    <ul>
                        <li>Locate the brake caliper, which is positioned above the brake rotor.</li>
                        <li>Depending on the vehicle, the caliper may be secured by bolts or guide pins.</li>
                        <li>Use a socket or wrench to remove the bolts or guide pins, allowing the caliper to move freely.</li>
                    </ul>
                </li>
                <li>Access the brake pads:
                    <ul>
                        <li>Gently lift the caliper, ensuring not to strain or damage the brake hose.</li>
                        <li>Remove the brake pads from their housing, taking note of their orientation for reinstallation.</li>
                    </ul>
                </li>
                <li>Inspect and clean:
                    <ul>
                        <li>Inspect the brake rotor for any signs of damage or excessive wear. Replace if necessary.</li>
                        <li>Using brake cleaner spray, clean the rotor and caliper thoroughly to remove any dirt or debris.</li>
                    </ul>
                </li>
                <li>Install the new brake pads:
                    <ul>
                        <li>Apply a thin layer of brake grease to the back of the new brake pads.</li>
                        <li>Install the new brake pads in the same orientation as the old ones.</li>
                        <li>Reinstall the brake caliper and secure it with the bolts or guide pins.</li>
                    </ul>
                </li>
                <li>Reinstall the wheel:
                    <ul>
                        <li>Reinstall the wheel and tighten the lug nuts by hand.</li>
                        <li>Lower the vehicle and tighten the lug nuts with a lug wrench or socket set.</li>
                    </ul>
                </li>
                <li>Test the brakes:
                    <ul>
                        <li>Before driving, pump the brake pedal a few times to restore pressure to the brake system.</li>
                        <li>Test the brakes at low speeds to ensure they're working properly.</li>
                    </ul>
                </li>
            </ol>
            <h3>And that's it! You've successfully changed your own brake pads.</h3>
        </div>
    </div>
    );
};

export default BrakePadChangeInstructions;