import CheckboxTile from '../../checkbox/checkbox.component';
import { conditionsArray } from '../../../data/vehicleOptions';
import ValidationMessage from '../../validation/validation.component';

const VehicleConditions = () => {
 
    return (
        <>
        <h2>Select Any Conditions You Might Drive In</h2>
        <ValidationMessage message={''} />
        {conditionsArray.map((type) => {
            return(
            <CheckboxTile key={type} label={type} value={type} />
            )
        })}

        </>
    )
}

export default VehicleConditions;