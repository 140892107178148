import React, { useState, useContext, useEffect } from "react";
import { VehicleContext } from '../use context/vehicle-object.context';
import './sidebar-styles.scss'
import { 
    bodyArray,
    drivetrainArray,
    transmissionArray,
    seatingArray,
    towingArray,
    fuelTypeArray,
    childrenArray,
    cargoArray,
    conditionsArray,
    activitiesArray,
    originArray
  } from "../../data/vehicleOptions";

import Slider from "../input/input";
import CheckboxTileSimple from "../checkbox/checkbox-simple.component";
import SliderSimple from "../input/input-simple";
import RadioButtonTileSimple from "../radio button/radio-button-simple.component";
import { act } from "react-dom/test-utils";


const ConstVehicleOptions = ({setUpdateReRunFunctionTrue}) => {

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    const { 
        minYear,
        maxYear,
        minPrice, 
        maxPrice, 
        cargoRating,
        maintenanceRating,
        safetyRating, 
        fuelEfficiency, 
        luxuryRating, 
        brandAwareness, 
        depreciation, 
        range,
        category, 
        subCategory,
        drivetrain,
        fuel,
        transmission,
        seating,
        towing,
        maxTowWeight,
        conditions, 
        activities,
        children,
        sportRating,
        originCountry
    } = vehicleObject

    const updateVehicleObject = (key, value) => {
        if (value.length > (key === "minYear" || key === "maxYear" ? 4 : 6)) return;
        setVehicleObject((prevObject) => ({ ...prevObject, [key]: value }));
      };
      
      const createUpdateHandler = (key) => (e) => {
        const value = e.target.value;
        updateVehicleObject(key, value);
      };
      
      const updateMinYear = createUpdateHandler("minYear");
      const updateMaxYear = createUpdateHandler("maxYear");
      const updateMinPrice = createUpdateHandler("minPrice");
      const updateMaxPrice = createUpdateHandler("maxPrice");

    const updateValueFuel = (newValue) => {
        setVehicleObject({...vehicleObject, fuelEfficiency: newValue})
    }
    const updateValueFuelRange = (newValue) => {
        setVehicleObject({...vehicleObject, range: newValue})
    }
    const updateValueSport = (newValue) => {
        setVehicleObject({...vehicleObject, sportRating: newValue})
    }
    const updateValueBrand = (newValue) => {
        setVehicleObject({...vehicleObject, brandAwareness: newValue})
    }
    const updateValueSafety = (newValue) => {
        setVehicleObject({...vehicleObject, safetyRating: newValue})
    }
    const updateValueLuxury = (newValue) => {
        setVehicleObject({...vehicleObject, luxuryRating: newValue})
    }
    const updateValueMaintenance = (newValue) => {
        setVehicleObject({...vehicleObject, maintenanceRating: newValue})
    }
    const updateValueDepreciation = (newValue) => {
        setVehicleObject({...vehicleObject, depreciation: newValue})
    }
    
    return (
        <div className="sidebar-container">
                <div className="flex-row-container">
                    <span style={{marginBottom:'-20px'}}>
                        <h4>Min Year</h4>
                        <input type="number" value={minYear} maxLength = "5" onChange={updateMinYear} />
                    </span>
                    <span style={{marginBottom:'-20px'}}>
                        <h4>Max Year</h4>
                        <input type="number" value={maxYear} onChange={updateMaxYear} />
                    </span>
                </div>
                <div className="flex-row-container">
                    <span>
                        <h4>Min Price</h4>
                        <b>$</b><input type="text" value={minPrice.toLocaleString()} onChange={updateMinPrice} />
                    </span>
                    <span>
                        <h4>Max Price</h4>
                        <b>$</b><input type="text" value={maxPrice.toLocaleString()} onChange={updateMaxPrice} />
                    </span>
                </div>
                <div>
                    <div>
                    <h4>Body Type</h4>
                    {bodyArray.map((type) => {
                        return(
                            <>
                                <CheckboxTileSimple key={type} label={type} value={type} onChange={(e) => setVehicleObject({...vehicleObject, category: e.target.value})} />
                            </>

                        )
                    })}
                    <h4>Drivetrain</h4>
                    {drivetrainArray.map((type) => {
                        return(
                            <>
                                <CheckboxTileSimple key={type} label={type} value={type} onChange={(e) => setVehicleObject({...vehicleObject, drivetrain: e.target.value})} />
                            </>
                        )
                    })}
                    <h4>Transmission</h4>
                    {transmissionArray.map((type) => {
                        return(
                            <>
                                <CheckboxTileSimple key={type} label={type} value={type} onChange={(e) => setVehicleObject({...vehicleObject, transmission: e.target.value})} />
                            </>
                        )
                    })}
                    <h4>Seats</h4>
                    {seatingArray.map((type) => {
                        return(
                            <>
                                <CheckboxTileSimple key={type} label={type} value={type} onChange={(e) => setVehicleObject({...vehicleObject, seating: e.target.value})} />
                            </>
                        )
                    })}
                    <h4>Will You Tow</h4>
                    <div className="flex-row-container">
                    {towingArray.map((type) => {
                        return(
                            <>
                                <RadioButtonTileSimple key={type} label={type} value={type} name="towing" property="towing" />
                            </>
                        )
                    })}
                    </div>
                    {towing === 'Yes' &&
                    <div className="towing-weight">
                        <p>Max Weight (lbs)</p><input type="number" value={maxTowWeight} onChange={(e) => setVehicleObject({...vehicleObject, maxTowWeight: e.target.value})} />
                    </div>}
                    <h4>Fuel Efficiency</h4>
                    <SliderSimple onValueChange={updateValueFuel} value={fuelEfficiency} min={1} max={10} />
                    <h4>Fuel Type</h4>
                    {fuelTypeArray.map((type) => {
                        return(
                            <>
                                <CheckboxTileSimple key={type} label={type} value={type} onChange={(e) => setVehicleObject({...vehicleObject, fuel: e.target.value})} />
                            </>
                        )
                    })}
                    {fuel.includes('Electric') &&
                    <div>
                    <h4>Electric Range</h4>
                    <SliderSimple onValueChange={updateValueFuelRange} value={range} min={50} max={500} step={10} />
                    </div>}
                    <h4>Children</h4>
                    <div className="flex-row-container">
                    {childrenArray.map((type) => {
                        return(
                            <>
                                <RadioButtonTileSimple key={type} label={type} value={type} name="children" property="children" onChange={(e) => setVehicleObject({...vehicleObject, children: e.target.value})} />
                            </>
                        )
                    })}
                    </div>
                    <h4>Cargo</h4>
                    {cargoArray.map((type) => {
                        return(
                            <>
                                <CheckboxTileSimple key={type} label={type} value={type} onChange={(e) => setVehicleObject({...vehicleObject, cargoRating: e.target.value})} />
                            </>
                        )
                    })}
                    <h4>Conditions</h4>
                    {conditionsArray.map((type) => {
                        return(
                            <>
                                <CheckboxTileSimple key={type} label={type} value={type} onChange={(e) => setVehicleObject({...vehicleObject, conditions: e.target.value})} />
                            </>
                        )
                    })}
                    <h4>Activities</h4>
                    {activitiesArray.map((type) => {
                        return(
                            <>
                                <CheckboxTileSimple key={type} label={type} value={type} onChange={(e) => setVehicleObject({...vehicleObject, activities: e.target.value})} />
                            </>
                        )
                    })}
                    <h4>Manufacturer</h4>
                    {originArray.map((type) => {
                        return(
                            <>
                                <CheckboxTileSimple key={type} label={type} value={type} onChange={(e) => setVehicleObject({...vehicleObject, originCountry: e.target.value})} />
                            </>
                        )
                    })}
                    <h4>Sport Rating</h4>
                    <SliderSimple onValueChange={updateValueSport} value={sportRating} min={1} max={10} step={1} />
                    <h4>Brand Rating</h4>
                    <SliderSimple onValueChange={updateValueBrand} value={brandAwareness} min={1} max={10} step={1} />
                    <h4>Safety Rating</h4>
                    <SliderSimple onValueChange={updateValueSafety} value={safetyRating} min={1} max={10} step={1} />
                    <h4>Luxury Rating</h4>
                    <SliderSimple onValueChange={updateValueLuxury} value={luxuryRating} min={1} max={10} step={1} />
                    <h4>Maintenance Rating</h4>
                    <SliderSimple onValueChange={updateValueMaintenance} value={maintenanceRating} min={1} max={10} step={1} />
                    <h4>Depreciation Rating</h4>
                    <SliderSimple onValueChange={updateValueDepreciation} value={depreciation} min={1} max={10} step={1} />
                    </div>
                </div>
                <button className='update-function-btn' onClick={()=>setUpdateReRunFunctionTrue()}>Update</button>
        </div>
    )
}


const SideBar = ({setUpdateReRunFunctionTrue}) => {

    // const objectKeys = Object.keys(vehicleObject);

    // const mappedObject = objectKeys.map((key) => {
    // return (
    //     <div key={key}>
    //         <span>{key}: </span>
    //         <span>{vehicleObject[key]}</span>
    //     </div>
    // );
    // });



    return (
        <div className='sidebar'>
            <ConstVehicleOptions setUpdateReRunFunctionTrue={setUpdateReRunFunctionTrue} />
        </div>
    )
}

export default SideBar;