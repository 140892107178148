import RadioButtonTile from "../../radio button/radio-button.component"
import { useContext, useState } from 'react';
import { VehicleContext } from "../../use context/vehicle-object.context";
import { towingArray } from "../../../data/vehicleOptions";
import ValidationMessage from "../../validation/validation.component";

import './vehicle-towing.styles.scss';

const VehicleTowing = () => {

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    const handleInputChange = (event) => {

        const { value } = event.target;

        setVehicleObject({
            ...vehicleObject,
            maxTowWeight: Number(value),
          });
      };

    return (
        <>
            <h2>* Will you ever tow with your vehicle?</h2>
            <ValidationMessage message={'Please select if you will tow or not'} />
            {towingArray.map((type) => {
                return(
                <RadioButtonTile key={type} label={type} value={type} name="towing" property="towing" />
                )
            })}
            {vehicleObject.towing =='Yes' ?
            <div className="tow-weight-container">
                <h3 className="tow-weight-header">Max Tow Weight (lbs):</h3> <input style={{width:'110px', height:'40px', fontSize:'32px'}} className="tow-weight-input" onChange={handleInputChange} value={vehicleObject.maxTowWeight}></input>
            </div> : null
            }
        </>
    )
}

export default VehicleTowing;