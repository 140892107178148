import { useState, useContext } from "react";
import { VehicleContext } from "../../../use context/vehicle-object.context";
import NavBar from "../../../navbar/navbar.componet";
import HeaderText from "../../../header text/header-text.component";
import Shapes from "../../../shapes/shapes.component";
import Footer from "../../../footer/footer.component";
import '../../../../index.scss'
import '../../index/index.style.scss'

const ContactUs = () => {

    const { mobileView } = useContext(VehicleContext)

        return (
            <div>
                <NavBar mobileScreen={mobileView} />     
                <div className="contact-us-container">
                    <div className="contact-us-info">
                        <div className="contact-us-form">
                        <HeaderText text="Contact Us" />
                        <h1>Lets Get In Touch.</h1>
                                <input type="text" placeholder="First Name" />
                                <input type="text" placeholder="Last Name" /><br/>
                                <input type="text" placeholder="Email" /><br/>
                                <input type="text" placeholder="Phone" /><br/>
                                <textarea placeholder="Message" /><br/>
                                <button>Submit</button>
                        </div>  
                        <Shapes className="shapes-1" h1Text={'We love feedback!'} pText={"Your thoughts matter to us! We value your feedback and want to hear what you have to say. Whether you have suggestions, questions, or simply want to share your experience, we're all ears. Reach out to us through the contact form or through our social media. Let's make great things together!"} />
                    </div> 
                </div>
                <Footer />
            </div>
        );
        }

export default ContactUs;
