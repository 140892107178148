import React, { useState, useEffect, useContext } from 'react';
import { Fade, Bounce, Zoom } from 'react-reveal';
import { VehicleContext } from '../use context/vehicle-object.context';
import ProgressBar from "@ramonak/react-progress-bar";
import InstructionPage from '../routes/instructions/instructions.component';
import VehicleYear from '../routes/vehicle year/vehicle-year.component';
import VehiclePrice from '../routes/vehicle price/vehicle-price.component';
import VehicleType from '../routes/vehicle type/vehicle-type.component';
import VehicleSubType from '../routes/vehicle subcategory/vehicle-subcategory.component';
import VehicleDriveTrain from '../routes/vehicle drivetrain/vehicle-drivetrain.component';
import VehicleFuelType from '../routes/vehicle fuel type/vehicle-fuel-type.component';
import VehicleTransmission from '../routes/vehicle transmission/vehicle-transmission.component';
import VehicleFuelEfficiency from '../routes/vehicle fuel efficiency/vehicle-fuel-efficiency.component';
import VehicleSeating from '../routes/vehicle seating/vehicle-seating.component';
import VehicleCargo from '../routes/vehicle cargo/vehicle-cargo.component';
import VehicleMaintenance from '../routes/vehicle maintenance/vehicle-maintenance.component';
import VehicleSafety from '../routes/vehicle safety/vehicle-safety.component';
import VehicleSelector from '../routes/vehicle selector/vehicle-selector.component';
import VehicleLuxury from '../routes/vehicle luxury/vehicle-luxury.component';
import VehicleBrand from '../routes/vehicle brand/vehicle-brand.component';
import VehicleResult from '../routes/vehicle result/vehicle-result.component';
import VehicleTowing from '../routes/vehicle towing/vehicle-towing.component';
import VehicleDepreciation from '../routes/vehicle depreciation/vehicle-depreciation.component';
import VehicleConditions from '../routes/vehicle conditions/vehicle-conditions.component';
import VehicleActivities from '../routes/vehicle activities/vehicle-activities.component';
import VehicleChildren from '../routes/vehicle children/vehicle-children.component';
import VehicleRange from '../routes/vehicle range/vehicle-range.component';
import VehicleSport from '../routes/vehicle sport/vehicle-sport.component';
import VehicleOrigin from '../routes/vehicle origin/vehicle-origin.component';
import VehicleCategoryScore1 from '../routes/vehicle category score/vehicle-category-score1.component';
import VehicleCategoryScore2 from '../routes/vehicle category score/vehicle-category-score2.component';
import VehicleSelector2 from '../routes/vehicle selector2/vehicle-selector2.component';
import SideBar from '../sidebar/sidebar-component';

import './card.component.style.scss'
import NavBar from '../navbar/navbar.componet';

const Card = () => {   

  const {vehicleObject, setVehicleObject, setValidationMessage, mobileView, setMobileView} = useContext(VehicleContext)

    const [count, setCount] = useState(0);
    const [hideNext, setHideNext] = useState(false);
    const [zeroCount, setZeroCount] = useState(true);
    const [hideSubCategory, setHideSubCategory] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const [isBouncing, setBouncing] = useState(false);
    const [updateReRunFunction, setUpdateReRunFunction] = useState(false);
    const [hideVehicleCards, setHideVehicleCards] = useState(false);
    const [vehicleSelector2Page, setVehicleSelector2Page] = useState(false);
    // const [selectAllTrue, setSelectAllTrue] = useState(false);

    const handleResize = () => {
      if (window.innerWidth < 767) {
        setMobileView(true)
        setShowSideBar(false)
      } else if (window.innerWidth >= 767 && count === (components.length - 1)) {
        // TODO: BOTH NEED TO BE CHANGED IN ORDER TO PREVENT SIDEBAR FROM SHOWING ON WINDOW RESIZE
        setMobileView(false)
        setShowSideBar(true)
        // setHideVehicleCards(!hideVehicleCards)
      }
    }

    useEffect(() => {
      handleResize();
  }, [])
    
    useEffect(() => {
      window.addEventListener("resize", handleResize)
    })

    useEffect(() => {
      const bounceInterval = setInterval(() => {
        setBouncing(prevBouncing => !prevBouncing);
      }, 3000);
  
      return () => {
        clearInterval(bounceInterval);
      };
    }, []);

    useEffect(() => {
        checkCount();
    }, [count])

    const setVehicleSelector2PageTrue = () => {
      setVehicleSelector2Page(true);
    };

    const setUpdateReRunFunctionTrue = () => {
      setUpdateReRunFunction(!updateReRunFunction);
      setHideVehicleCards(false)
      setShowSideBar(false)
    };

    const hideVehicleCardsFunction = () => {
      setHideVehicleCards(!hideVehicleCards)
      setShowSideBar(!showSideBar)
      // setShowSideBar(true)
    }

    const setHideNextBtn = () => {
        setHideNext(true);
      };

    const setShowNextBtn = () => {
        setHideNext(false);
      };

    const setShowSideBarTrue = () => {
        setShowSideBar(true);
      };

    const setShowSideBarFalse = () => {
        setShowSideBar(false);
      };

    // const selectAll = () => {
    //    setSelectAllTrue(true)
    //   };
    
    let components = [];

    const renderCurrentSelection = () => {

        components = [
            <InstructionPage />,
            <VehicleYear />,
            <VehiclePrice />,
            <VehicleType />,
            // ... vehicleObject.category.includes("Truck") ? 
            // [<VehicleSubType />] : 
            // [],
            <VehicleDriveTrain />,
            <VehicleTransmission />,
            <VehicleSeating />,
            <VehicleTowing />,
            <VehicleFuelType />,
            // add count check so that the components array does not change when the user is on last page updating the sidebar fuel 
            ... vehicleObject.fuel.includes("Electric") && count < 16 ? 
            [<VehicleRange />] :
            [],
            ... vehicleObject.fuel == "Electric" && count < 16 ? 
            [] :
            [<VehicleFuelEfficiency />],
            <VehicleChildren />,
            <VehicleCargo />,
            <VehicleConditions />,
            <VehicleActivities />,
            <VehicleOrigin />,
            <VehicleSport />,
            <VehicleBrand />,
            <VehicleSafety />,
            <VehicleLuxury />,
            <VehicleMaintenance />,
            <VehicleDepreciation />,
            <VehicleCategoryScore1 />,
            <VehicleCategoryScore2 setShowNextBtn={setShowNextBtn} />,
            // <VehicleResult />,
            // <VehicleSelector setHideNextBtn={setHideNextBtn} />
            <VehicleSelector2 setShowSideBarFalse={setShowSideBarFalse} setVehicleSelector2PageTrue={setVehicleSelector2PageTrue} setHideNextBtn={setHideNextBtn} setShowSideBarTrue={setShowSideBarTrue} updateReRunFunction={updateReRunFunction} hideVehicleCardsFunction={hideVehicleCardsFunction} hideVehicleCards={hideVehicleCards} handleResize={handleResize} />
          ];

        return count >= 0 && count < components.length ? components[count] : null;
    };

      const nextComponent = () => {
        switch (count) {
          case 3:
          //   if(selectAllTrue) {
          //   setVehicleObject({
          //     ...vehicleObject,
          //     category: ["Car", "Pickup Truck", "SUV", "Van"],
          //   }); 
          // }
            if (vehicleObject.category.length !== 0) {
              setCount(count + 1);
              setValidationMessage(false);
            } else {
                setValidationMessage(true);
            }
            break;
          case 4:
            if (vehicleObject.drivetrain.length !== 0) {
              setCount(count + 1);
              setValidationMessage(false);
            } else {
                setValidationMessage(true);
            }
            break;
          case 5:
            if (vehicleObject.transmission.length !== 0) {
              setCount(count + 1);
              setValidationMessage(false);
            } else {
                setValidationMessage(true);
            }
            break;
          case 6:
            if (vehicleObject.seating.length !== 0) {
              setCount(count + 1);
              setValidationMessage(false);
            } else {
                setValidationMessage(true);
            }
            break;
          case 7:
            if (vehicleObject.towing.length !== 0) {
              setCount(count + 1);
              setValidationMessage(false);
            } else {
                setValidationMessage(true);
            }
            break;
          case 8:
            if (vehicleObject.fuel.length !== 0) {
              setCount(count + 1);
              setValidationMessage(false);
            } else {
                setValidationMessage(true);
            }
            break;
          default:
            setCount(count + 1);
            break;
        }
                  
        function handleVehicleObjectCountry() {
            if (vehicleObject.originCountry.length !== 0) {
              setCount(count + 1);
              setValidationMessage(false);
            } else {            
                setValidationMessage(true);
              setCount(count);
            }
          }

        function handleVehicleObjectChildren() {
            if (vehicleObject.children.length !== 0) {
              setCount(count + 1);
              setValidationMessage(false);
            } else {            
                setValidationMessage(true);
                setCount(count);
            }
          }

        if (!vehicleObject.fuel.includes("Electric") || (vehicleObject.fuel.length === 1 && vehicleObject.fuel[0] == "Electric")) {
            if (count === 10) {
            handleVehicleObjectChildren();
            }
        }

        if (vehicleObject.fuel.includes("Electric") && vehicleObject.fuel.length > 1) {
            if (count === 11) {
                handleVehicleObjectChildren();
            }
        }

        if (vehicleObject.fuel.includes("Electric")) {
            if (count === 15) {
                handleVehicleObjectCountry();
            }
          } else {
            if (count === 14) {
                handleVehicleObjectCountry();
            }
          }
      };

    const backComponent = () => {
        setCount(count - 1)
        setShowSideBar(false)
    }

    const checkCount = () => {
    if(count === 0) {
        setZeroCount(true)
    } else {
        setZeroCount(false)
    }
}

    return(
        <>
          <NavBar mobileScreen={mobileView} />
          <ProgressBar 
          completed={count}
          maxCompleted={vehicleObject.fuel.includes("Electric") ? 22:22}
          bgColor='white'
          className="wrapper"
          barContainerClassName="container"
          />
          <div style={vehicleSelector2Page ? {top:'0%'}:{top:'20%'}} className={showSideBar ? 'card-sidebar-component-container' : 'card-component-container'}>
            {/* <button style={{zIndex:1000}} onClick={selectAll}>Select All</button> */}
              <div className={showSideBar ? 'card-sidebar-component' : 'card-component'}>
                {renderCurrentSelection()}
                <div>
                  {showSideBar ? <SideBar setUpdateReRunFunctionTrue={setUpdateReRunFunctionTrue} /> : null}
                  {/* <button>Update</button> */}
                </div>
              </div>
              {zeroCount ?
              <div className='start-here-bubble-container start-here-hide'>
                  <div className={isBouncing ? 'bounce-animation' : ''}>
                  <Bounce delay={9500}>
                      <div className='start-here-bubble-text'>Lets Start!</div>
                  </Bounce>
                  </div>
                  <Bounce delay={10000}>
                      <img className='down-arrow1 start-here-hide' src={require('../images/downArrow.png')} />
                  </Bounce>
                  <Bounce delay={10500}>
                      <img className='down-arrow2 start-here-hide' src={require('../images/downArrow.png')} />
                  </Bounce>
              </div> :
              null}
              {hideNext ? null : <button className='next-button navigation-button' onClick={nextComponent}>Next</button>}
              {zeroCount || hideNext ? null : <button className='back-button navigation-button' onClick={backComponent}>Back</button>}
          </div>
        </>
    )
}

export default Card;