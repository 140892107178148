export const bodyArray = ['Car', 'SUV', 'Van', 'Pickup Truck']
export const drivetrainArray = ['Four Wheel Drive', 'Front Wheel Drive', 'All Wheel Drive', 'Rear Wheel Drive']
export const transmissionArray = ['Automatic', 'Manual']
export const seatingArray = ["2","5","7","8"]
export const towingArray = ['Yes', 'No']
export const fuelTypeArray = ['Electric', 'Hybrid', 'Diesel', 'Gas']
export const childrenArray = ['No', '1', '2', '3+']
export const cargoArray = ['Sports Gear', 'Dogs', 'Cargo']
export const conditionsArray = ['City', 'Mountains', 'Unpaved']
export const activitiesArray = ['Skiing', 'Hiking', 'Camping', 'Biking', 'Road Trips']
export const originArray = ['USA', 'Asia', 'Europe']


export default {
    bodyArray,
    drivetrainArray,
    transmissionArray,
    seatingArray,
    towingArray,
    fuelTypeArray,
    childrenArray,
    cargoArray,
    conditionsArray,
    activitiesArray,
    originArray
}