import * as React from 'react';
import { useState, useContext } from 'react';
import axios from 'axios';
import DatabaseSearch from './databaseSearch.component';
import './vehicleDatabase.style.scss';
import { VehicleContext } from '../../use context/vehicle-object.context';
import NavBar from '../../navbar/navbar.componet';
import Loader from '../../loader/loader-component';
import Footer from '../../footer/footer.component';

const VehicleDatabaseTool = () => {

    const [vehicleData, setVehicleData] = useState([]);
    const [noResultsMsg, setNoResultsMsg] = useState(false)
    const [showLoader, setShowLoader] =useState(false)
    const [vehicleOptions, setVehicleOptions] = useState({
        minYear: null,
        maxYear: null,
        minPrice: null,
        maxPrice: null,
        make: null,
        model: null,
        body: null,
        bodySpecific: null,
        doors: null,
        seats: null,
        cylinders: null,
        transmission: null,
        drivetrain: null,
        fuelType: null,
        fuelMpg: null,
        manufacturerCountry: null,
      });

      const { mobileView, setMobileView } = useContext(VehicleContext)

    const updateVehicleOptions = (category, value) => {
        if(value.includes('Select')) {
            setVehicleOptions({
                ...vehicleOptions,
                [category]: null
            })
        } else {
            setVehicleOptions({
            ...vehicleOptions,
            [category]: value
        })
        }
    }

    // useEffect(() => {
    //     getData();
    // }, []);

    const updateSearch = () => {
        getData()
    }

    const getData = () => {
        
        setShowLoader(true)
      
        axios.get('https://vehicle-database-selector.herokuapp.com/vehicleDatabaseSearch', {
            params: {

                minYear:vehicleOptions.minYear,
                maxYear:vehicleOptions.maxYear,
                minPrice: vehicleOptions.minPrice,
                maxPrice: vehicleOptions.maxPrice,
                make: vehicleOptions.make,
                model: vehicleOptions.model,
                body: vehicleOptions.body,
                bodySpecific: vehicleOptions.bodySpecific,
                doors: vehicleOptions.doors,
                seats: vehicleOptions.seats,
                cylinders: vehicleOptions.cylinders,
                transmission: vehicleOptions.transmission,
                drivetrain: vehicleOptions.drivetrain,
                fuelType: vehicleOptions.fuelType,
                fuelMpg: vehicleOptions.fuelMpg,
                manufacturerCountry: vehicleOptions.manufacturerCountry,
            }
        })
        .then((res) => {
            setShowLoader(false)
            setVehicleData(res.data)
            console.log(res.data)
            if(res.data.length === 0) {
                setNoResultsMsg(true)
            } else {
                setNoResultsMsg(false)
            }
        })
        .catch(err => {
            console.log('axios/heroku call error');
            console.error(err.response.data);    // ***
            console.error(err.response.status);  // ***
        })
        
        };

    return (
        <>
            <NavBar mobileScreen={mobileView} />
            {showLoader &&
                <Loader />
            }
            <div className='vehicle-search-container'>
                <DatabaseSearch updateSearch={updateSearch} updateVehicleOptions={updateVehicleOptions} />
                <div className='vehicle-database-table-container'>{
                    vehicleData.length > 0 &&                
                    <table>
                        <thead>
                            <tr>
                                <th>make</th>
                                <th>model</th>
                                <th>year</th>
                                <th>msrp_cdn</th>
                                <th>body_general</th>
                                <th>body_type</th>
                                <th>transmission_short</th>
                                <th>drive_train</th>
                                <th>engine_size</th>
                                <th>cylinders</th>
                                <th>horsepower</th>
                                <th>combined_mpg</th>
                                <th>fuel_short</th>
                                <th>electric_range_km</th>
                                <th>doors</th>
                                <th>seats</th>
                                <th>max_tow_lbs</th>
                                <th>country_general</th>
                            </tr>
                        </thead>
                        <tbody>
                        {vehicleData.map((vehicle) => (
                            <tr key={vehicle.id}>
                                <td>{vehicle.make}</td>
                                <td>{vehicle.model}</td>
                                <td>{vehicle.year}</td>
                                <td>${vehicle.msrp_cdn.toLocaleString()}</td>
                                <td>{vehicle.body_general}</td>
                                <td>{vehicle.body_type}</td>
                                <td>{vehicle.transmission_short}</td>
                                <td>{vehicle.drive_train}</td>
                                <td>{vehicle.engine_size}</td>
                                <td>{vehicle.cylinders}</td>
                                <td>{vehicle.horsepower}</td>
                                <td>{vehicle.combined_mpg}</td>
                                <td>{vehicle.fuel_short}</td>
                                <td>{vehicle.electric_range_km}</td>
                                <td>{vehicle.doors}</td>
                                <td>{vehicle.seats}</td>
                                <td>{vehicle.max_tow_lbs}</td>
                                <td>{vehicle.country}</td>
                            </tr>
                        ))} 
                        </tbody>
                    </table>
                    }
                    {noResultsMsg && 
                    <span className='no-results-msg'>No Vehicles Match Your Search</span>
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default VehicleDatabaseTool;