import React from 'react';
import { Fade, Bounce, Zoom } from 'react-reveal';


const HoverItem = ({ vehicle, hoveredItem, showVehicleInfoPage, setShowSideBarFalse, generateVehicleWriteUp, testScore, firstVehicle }) => {

    const renderScore = (scoreName, scoreValue) => {
        const className = scoreValue > 0.7 ? 'high-score' : scoreValue < 0.5 ? 'low-score' : '';
        const formattedScore = Math.ceil(scoreValue * 100);
        return (
          <li className={className}>
            {scoreName} Match: {formattedScore}%
          </li>
        );
      };
    return(
    <>
    {hoveredItem === vehicle.id && (
        <Fade>
          <div className='vehicle-info-hover'>
            <h5>{vehicle.year} {vehicle.make} {vehicle.model}</h5>
            <h5 style={{fontWeight:'500'}}>Score: {vehicle.score}</h5>
              <ul>
                {renderScore('Cargo', vehicle.vehicleCargoScore)}
                {renderScore('Maintenance', vehicle.vehicleMaintenanceScore)}
                {renderScore('Depreciation', vehicle.vehicleDepreciationScore)}
                {renderScore('Safety', vehicle.vehicleSafetyScore)}
                {renderScore('Luxury', vehicle.vehicleLuxuryScore)}
                {renderScore('Brand', vehicle.vehicleBrandScore)}
                {renderScore('Activity', vehicle.vehicleActivityScore)}
                {renderScore('Conditions', vehicle.vehicleConditionsScore)}
                {renderScore('Sport', vehicle.vehicleSportScore)}
              </ul>
            </div>
            <button className='learn-more-btn' onClick={() => {    
            showVehicleInfoPage(vehicle)
            setShowSideBarFalse()
            
            // generateVehicleWriteUp(
            //   Math.trunc(testScore/firstVehicle.score*100),
            //   vehicle.luxury_score,
            //   vehicle.safety_score,
            //   vehicle.maintenance_score,
            //   vehicle.depreciation_score,
            //   vehicle.brand_score,
            //   vehicle.activity_score,
            //   vehicle.sport_score,
            //   vehicle.year,
            //   vehicle.make,
            //   vehicle.model,
            //   vehicle.vehicleCargoScore, 
            //   vehicle.vehicleMaintenanceScore, 
            //   vehicle.vehicleDepreciationScore, 
            //   vehicle.vehicleSafetyScore, 
            //   vehicle.vehicleLuxuryScore, 
            //   vehicle.vehicleBrandScore, 
            //   vehicle.vehicleActivityScore, 
            //   vehicle.vehicleConditionsScore,
            //   vehicle.vehicleSportScore,
            //   )
            }}>Learn More</button>
        </Fade>
    )}
    </>
    )
};

export default HoverItem;