import CheckboxTile from '../../checkbox/checkbox.component';
import { originArray } from '../../../data/vehicleOptions';
import ValidationMessage from '../../validation/validation.component';

const VehicleOrigin = () => {

    return (
        <>
            <h2>* Select Preferred Manufacturer Origins</h2>
            <ValidationMessage message={'Please select at least one option'} />
            {originArray.map((type) => {
                return(
                <CheckboxTile key={type} label={type} value={type} />
                )
            })}
        </>
    )
}

export default VehicleOrigin;