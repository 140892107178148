import React, { useState, useContext, useEffect } from "react";
import { VehicleContext } from "../use context/vehicle-object.context";
import './radio-button.style.scss';

function RadioButtonTileSimple({ label, value, name, property }) {
  const { vehicleObject, setVehicleObject } = useContext(VehicleContext);
  const [isChecked, setIsChecked] = useState(vehicleObject[property] === value);

  useEffect(() => {
    setIsChecked(vehicleObject[property] === value);
  }, [vehicleObject, property, value]);

  const handleRadioChange = (event) => {
    const { value } = event.target;

    if (value === "Yes" || value === "No" || value === "1" || value === "2" || value === "3+") {
      setVehicleObject({
        ...vehicleObject,
        [property]: value,
      });
      setIsChecked(value);
    }
  };

  return (
    <div className="radio-btn-simple-container">
      <p className="radio-btn-label">{label}</p>
      <input
        className="radio-btn-simple"
        type="radio"
        value={value}
        name={name}
        checked={isChecked}
        onChange={handleRadioChange}
      />
    </div>
  );
}

export default RadioButtonTileSimple;
