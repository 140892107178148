import { useState, useContext } from "react";
import { VehicleContext } from "../../use context/vehicle-object.context";
import Slider from "../../input/input";

const VehicleBrand = () => {

    const {vehicleObject, setVehicleObject} = useContext(VehicleContext)

    const updateValue = (newValue) => {
        setVehicleObject({...vehicleObject, brandAwareness: newValue})
    }

    return(
        <div className='slider'>
        <h2>Select Preferred Brand Rating</h2>
        <Slider className={'slider-parent'} onValueChange={updateValue} value={vehicleObject.brandAwareness} lowMsg="less known" highMsg="more known" />
        </div>
    )
}

export default VehicleBrand;